import { lazy } from "react";
import page404 from "../pages/page404";
const attendee = lazy(() => import("../pages/event/registrations"));
const approval = lazy(() => import("../pages/event/approval"));
const dashboard = lazy(() => import("../pages/dashboard"));
const regdata = lazy(() => import("../pages/registrationFormData"));
const customDomain = lazy(() => import("../pages/event/customDomain"));
const emailCampaign = lazy(() => import("../pages/emailCampaign/EmailCampaignList"));
const whatsappCampaign = lazy(() => import("../pages/whatsappCampaign"));
const qrCode = lazy(() => import("../pages/qrCode"));
const faceMatch = lazy(() => import("../pages/event/instaPhotoAttendee"));
const recapUser = lazy(() => import("../pages/event/instaRecapAttendee"));

const RenderSubPage = (element, content) => {
  if (content) {
    return content;
  } else {
    switch (element.page) {
      case "attendee":
        return attendee;
      case "dashboard":
        return dashboard;
      case "approval":
        return approval;
      case "regdata":
        return regdata;
      case "registration":
        return attendee;
      case "domain":
        return customDomain;
      case "emailCampaign":
        return emailCampaign;
      case "whatsappCampaign":
        return whatsappCampaign;
      case "qrCode":
        return qrCode;
      case "faceMatch":
        return faceMatch;
      case "recapUser":
        return recapUser;
      default:
        return page404;
    }
  }
};

export default RenderSubPage;
