import React from "react";
import styled from "styled-components";
import { GetCustomIcon } from "../components/project/icons";
// Styled SVG with rotation animation
const Svg = styled.svg`
  height: 1em;
  @keyframes rotate {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }

  &.rotate-icon {
    animation: rotate 2s linear infinite; /* Adjust the duration and easing as necessary */
  }
`;
export const HomeIcon = () => (
  <Svg className="svg-inline--fa fa-house-blank" aria-hidden="true" focusable="false" data-prefix="far" data-icon="house-blank" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512" data-fa-i2svg="">
    <path fill="currentColor" d="M303.5 5.7c-9-7.6-22.1-7.6-31.1 0l-264 224c-10.1 8.6-11.3 23.7-2.8 33.8s23.7 11.3 33.8 2.8L64 245.5V432c0 44.2 35.8 80 80 80H432c44.2 0 80-35.8 80-80V245.5l24.5 20.8c10.1 8.6 25.3 7.3 33.8-2.8s7.3-25.3-2.8-33.8l-264-224zM464 204.8V432c0 17.7-14.3 32-32 32H144c-17.7 0-32-14.3-32-32V204.8L288 55.5 464 204.8z"></path>
  </Svg>
);
const EmailIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 512 512" fill="currentColor">
    <path fill="currentColor" d="M64 96c-17.7 0-32 14.3-32 32l0 39.9L227.6 311.3c16.9 12.4 39.9 12.4 56.8 0L480 167.9l0-39.9c0-17.7-14.3-32-32-32L64 96zM32 207.6L32 384c0 17.7 14.3 32 32 32l384 0c17.7 0 32-14.3 32-32l0-176.4L303.3 337.1c-28.2 20.6-66.5 20.6-94.6 0L32 207.6zM0 128C0 92.7 28.7 64 64 64l384 0c35.3 0 64 28.7 64 64l0 256c0 35.3-28.7 64-64 64L64 448c-35.3 0-64-28.7-64-64L0 128z" />
  </svg>
);
export const DwonlaodIcon = () => (
  <Svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 512 512">
    <path fill="currentColor" d="M272 16c0-8.8-7.2-16-16-16s-16 7.2-16 16V329.4L139.3 228.7c-6.2-6.2-16.4-6.2-22.6 0s-6.2 16.4 0 22.6l128 128c6.2 6.2 16.4 6.2 22.6 0l128-128c6.2-6.2 6.2-16.4 0-22.6s-16.4-6.2-22.6 0L272 329.4V16zM140.1 320H64c-35.3 0-64 28.7-64 64v64c0 35.3 28.7 64 64 64H448c35.3 0 64-28.7 64-64V384c0-35.3-28.7-64-64-64H371.9l-32 32H448c17.7 0 32 14.3 32 32v64c0 17.7-14.3 32-32 32H64c-17.7 0-32-14.3-32-32V384c0-17.7 14.3-32 32-32H172.1l-32-32zM432 416a24 24 0 1 0 -48 0 24 24 0 1 0 48 0z" />{" "}
  </Svg>
);
export const FaqIcon = () => (
  <Svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512">
    <path d="M78.2 274.7L70.9 293 52.4 339.1l99.9-23 6.7-1.6 6.8 1.4c13.5 2.7 27.6 4.2 42.2 4.2c102.4 0 176-69.3 176-144s-73.6-144-176-144S32 101.3 32 176c0 30.8 11.9 59.8 33.2 84l13 14.8zM38 375.2L0 384l14.5-36.2 26.7-66.7C15.3 251.8 0 215.4 0 176C0 78.8 93.1 0 208 0S416 78.8 416 176s-93.1 176-208 176c-16.7 0-32.9-1.7-48.5-4.8L38 375.2zm207.2 6.2c11.5-1.6 22.8-3.9 33.8-6.8C308.4 417.3 363.8 448 432 448c14.6 0 28.7-1.5 42.2-4.2l6.8-1.4 6.7 1.6 99.9 23L569.1 421l-7.3-18.3 13-14.8c21.3-24.1 33.2-53.2 33.2-84c0-70.9-66.3-137-160.6-143.5c-.9-11.1-2.8-21.9-5.6-32.3C552.1 132.5 640 209.6 640 304c0 39.4-15.3 75.8-41.2 105.1l26.7 66.7L640 512l-38-8.8-121.5-28c-15.6 3.1-31.8 4.8-48.5 4.8c-82 0-153-40.2-186.8-98.5zM185.3 80.7H225c27.7 0 50 22.7 50 50.2c0 18.7-10.5 36.1-27.3 44.7L224 187.6v15.7H192v-16-9.5V168l8.7-4.5L233.2 147c6-3 9.8-9.3 9.8-16.1c0-10.1-8.2-18.2-18-18.2H185.3c-6.8 0-12.3 5.5-12.3 12.3v5.7H141V125c0-24.5 19.8-44.3 44.3-44.3zM225 221.3v34H191v-34h34zm325.6 56l-11.3 11.3L454 374l-11.3 11.3L431.4 374l-42.7-42.7L377.4 320 400 297.4l11.3 11.3L442.7 340l74-74L528 254.7l22.6 22.6z" />
  </Svg>
);
export const Download = () => (
  <Svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 448 512">
    <path d="M32 96c0-17.7 14.3-32 32-32l0 96c0 17.7 14.3 32 32 32l192 0c17.7 0 32-14.3 32-32l0-94.2c4.5 1.6 8.7 4.2 12.1 7.6l74.5 74.5c6 6 9.4 14.1 9.4 22.6L416 416c0 17.7-14.3 32-32 32L64 448c-17.7 0-32-14.3-32-32L32 96zM96 64l192 0 0 96L96 160l0-96zM0 96L0 416c0 35.3 28.7 64 64 64l320 0c35.3 0 64-28.7 64-64l0-245.5c0-17-6.7-33.3-18.7-45.3L354.7 50.7c-12-12-28.3-18.7-45.3-18.7L64 32C28.7 32 0 60.7 0 96zM272 320a48 48 0 1 1 -96 0 48 48 0 1 1 96 0zm-48-80a80 80 0 1 0 0 160 80 80 0 1 0 0-160z" />
  </Svg>
);
export const ContactIcon = () => (
  <Svg className="svg-inline--fa fa-messages" aria-hidden="true" focusable="false" data-prefix="far" data-icon="messages" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512" data-fa-i2svg="">
    <path
      fill="currentColor"
      d="M48 72c0-13.3 10.7-24 24-24H344c13.3 0 24 10.7 24 24V248c0 13.3-10.7 24-24 24H216c-4.7 0-9.4 1.4-13.3 4L144 315.2V296c0-13.3-10.7-24-24-24H72c-13.3 0-24-10.7-24-24V72zM72 0C32.2 0 0 32.2 0 72V248c0 39.8 32.2 72 72 72H96v40c0 8.9 4.9 17 12.7 21.2s17.3 3.7 24.6-1.2l90-60H344c39.8 0 72-32.2 72-72V72c0-39.8-32.2-72-72-72H72zM256 376c0 39.8 32.2 72 72 72h88.7l90 60c7.4 4.9 16.8 5.4 24.6 1.2S544 496.9 544 488V448h24c39.8 0 72-32.2 72-72V200c0-39.8-32.2-72-72-72H448v48H568c13.3 0 24 10.7 24 24V376c0 13.3-10.7 24-24 24H520c-13.3 0-24 10.7-24 24v19.2L437.3 404c-3.9-2.6-8.6-4-13.3-4H328c-13.3 0-24-10.7-24-24V352H256v24z"
    ></path>
  </Svg>
);
export const ContactLargeIcon = () => (
  <Svg className="svg-inline--fa fa-messages" aria-hidden="true" focusable="false" data-prefix="fal" data-icon="messages" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512" data-fa-i2svg="">
    <path
      fill="currentColor"
      d="M576 128H448v32h128c17.64 0 32 14.36 32 32v192c0 17.64-14.36 32-32 32h-64v49.57L424.4 416H320c-17.64 0-32-14.36-32-32v-32l-32-.0039v31.98c0 35.25 28.75 63.1 63.1 63.1L416 448l108.9 61.63C527.1 511.3 529.6 512 532 512C538.3 512 544 507.1 544 500v-52.03h32c35.25 0 64-28.75 64-63.1V191.1C640 156.7 611.3 128 576 128zM416 256V63.1C416 28.75 387.3 0 352 0H64C28.75 0 0 28.75 0 63.1v192C0 291.3 28.75 320 64 320l32 .0106V372C96 379.1 101.8 384 108 384c2.375 0 4.875-.75 7.125-2.375L224 320l128-.075C387.3 319.9 416 291.3 416 256zM215.6 288L128 337.6V288H64C46.36 288 32 273.6 32 256V64c0-17.64 14.36-32 32-32h288c17.64 0 32 14.36 32 32v192c0 17.64-14.36 32-32 32H215.6z"
    ></path>
  </Svg>
);
export const CloseIcon = () => (
  <Svg className="svg-inline--fa fa-xmark" aria-hidden="true" focusable="false" data-prefix="far" data-icon="xmark" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512" data-fa-i2svg="">
    <path fill="currentColor" d="M313 137c9.4-9.4 9.4-24.6 0-33.9s-24.6-9.4-33.9 0l-119 119L41 103c-9.4-9.4-24.6-9.4-33.9 0s-9.4 24.6 0 33.9l119 119L7 375c-9.4 9.4-9.4 24.6 0 33.9s24.6 9.4 33.9 0l119-119L279 409c9.4 9.4 24.6 9.4 33.9 0s9.4-24.6 0-33.9l-119-119L313 137z"></path>
  </Svg>
);
export const LogoutIcon = () => (
  <Svg className="svg-inline--fa fa-arrow-right-from-bracket" aria-hidden="true" focusable="false" data-prefix="far" data-icon="arrow-right-from-bracket" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" data-fa-i2svg="">
    <path fill="currentColor" d="M505 273c9.4-9.4 9.4-24.6 0-33.9L377 111c-9.4-9.4-24.6-9.4-33.9 0s-9.4 24.6 0 33.9l87 87L184 232c-13.3 0-24 10.7-24 24s10.7 24 24 24l246.1 0-87 87c-9.4 9.4-9.4 24.6 0 33.9s24.6 9.4 33.9 0L505 273zM168 80c13.3 0 24-10.7 24-24s-10.7-24-24-24L88 32C39.4 32 0 71.4 0 120L0 392c0 48.6 39.4 88 88 88l80 0c13.3 0 24-10.7 24-24s-10.7-24-24-24l-80 0c-22.1 0-40-17.9-40-40l0-272c0-22.1 17.9-40 40-40l80 0z"></path>
  </Svg>
);
export const DateIcon = () => (
  <Svg className="svg-inline--fa fa-calendar" aria-hidden="true" focusable="false" data-prefix="fal" data-icon="calendar" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" data-fa-i2svg="">
    <path fill="currentColor" d="M112 0C120.8 0 128 7.164 128 16V64H320V16C320 7.164 327.2 0 336 0C344.8 0 352 7.164 352 16V64H384C419.3 64 448 92.65 448 128V448C448 483.3 419.3 512 384 512H64C28.65 512 0 483.3 0 448V128C0 92.65 28.65 64 64 64H96V16C96 7.164 103.2 0 112 0zM416 192H32V448C32 465.7 46.33 480 64 480H384C401.7 480 416 465.7 416 448V192zM384 96H64C46.33 96 32 110.3 32 128V160H416V128C416 110.3 401.7 96 384 96z"></path>
  </Svg>
);
export const FileIcon = () => (
  <Svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 576 512">
    <path
      fill="currentColor"
      d="M448 160v32h32V160c0-35.3-28.7-64-64-64H298.5c-17 0-33.3-6.7-45.3-18.7L226.7 50.7c-12-12-28.3-18.7-45.3-18.7H64C28.7 32 0 60.7 0 96V416c0 35.3 28.7 64 64 64H384h80c19.6 0 37.3-11.9 44.6-30.2l64-160c5.9-14.8 4.1-31.5-4.8-44.7S543.9 224 528 224H128c-19.6 0-37.3 11.9-44.6 30.2L32 382.8V96c0-17.7 14.3-32 32-32H181.5c8.5 0 16.6 3.4 22.6 9.4l22.6-22.6L204.1 73.4l26.5 26.5c18 18 42.4 28.1 67.9 28.1H416c17.7 0 32 14.3 32 32zM384 448H64c-5.3 0-10.3-2.6-13.2-7s-3.6-10-1.6-14.9l64-160c2.4-6.1 8.3-10.1 14.9-10.1H528c5.3 0 10.3 2.6 13.2 7s3.6 10 1.6 14.9l-64 160C476.4 444 470.5 448 464 448H384z"
    />
  </Svg>
);
export const OpneIcon = () => (
  <Svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 512 512">
    <path fill="currentColor" d="M480 256A224 224 0 1 1 32 256a224 224 0 1 1 448 0zM0 256a256 256 0 1 0 512 0A256 256 0 1 0 0 256zM235.3 379.3l112-112c6.2-6.2 6.2-16.4 0-22.6l-112-112c-6.2-6.2-16.4-6.2-22.6 0s-6.2 16.4 0 22.6L313.4 256 212.7 356.7c-6.2 6.2-6.2 16.4 0 22.6s16.4 6.2 22.6 0z" />
  </Svg>
);
export const ExternalIcon1 = () => (
  <Svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 512 512">
    <path d="M336 0c-8.8 0-16 7.2-16 16s7.2 16 16 16H457.4L212.7 276.7c-6.2 6.2-6.2 16.4 0 22.6s16.4 6.2 22.6 0L480 54.6V176c0 8.8 7.2 16 16 16s16-7.2 16-16V16c0-8.8-7.2-16-16-16H336zM64 32C28.7 32 0 60.7 0 96V448c0 35.3 28.7 64 64 64H416c35.3 0 64-28.7 64-64V304c0-8.8-7.2-16-16-16s-16 7.2-16 16V448c0 17.7-14.3 32-32 32H64c-17.7 0-32-14.3-32-32V96c0-17.7 14.3-32 32-32H208c8.8 0 16-7.2 16-16s-7.2-16-16-16H64z" />
  </Svg>
);
export const OpenIcon = () => (
  <Svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 512 512">
    <path fill="currentColor" d="M480 256A224 224 0 1 1 32 256a224 224 0 1 1 448 0zM0 256a256 256 0 1 0 512 0A256 256 0 1 0 0 256zM235.3 379.3l112-112c6.2-6.2 6.2-16.4 0-22.6l-112-112c-6.2-6.2-16.4-6.2-22.6 0s-6.2 16.4 0 22.6L313.4 256 212.7 356.7c-6.2 6.2-6.2 16.4 0 22.6s16.4 6.2 22.6 0z" />
  </Svg>
);
export const CarIcon = () => (
  <Svg className="svg-inline--fa fa-car" aria-hidden="true" focusable="false" data-prefix="far" data-icon="car" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" data-fa-i2svg="">
    <path fill="currentColor" d="M127.7 106.8L103.4 176H408.6l-24.2-69.2c-5.6-16-20.8-26.8-37.8-26.8H165.4c-17 0-32.1 10.7-37.8 26.8zm-79.6 82L82.3 90.9C94.7 55.6 128 32 165.4 32H346.6c37.4 0 70.7 23.6 83.1 58.9l34.3 97.9C492.6 205.4 512 236.4 512 272v80 48 56c0 13.3-10.7 24-24 24s-24-10.7-24-24V400H48v56c0 13.3-10.7 24-24 24s-24-10.7-24-24V400 352 272c0-35.6 19.3-66.6 48.1-83.2zM416 224H96c-26.5 0-48 21.5-48 48v80H464V272c0-26.5-21.5-48-48-48zM112 256a32 32 0 1 1 0 64 32 32 0 1 1 0-64zm256 32a32 32 0 1 1 64 0 32 32 0 1 1 -64 0z"></path>
  </Svg>
);
export const DurationIcon = () => (
  <Svg className="svg-inline--fa fa-timer" aria-hidden="true" focusable="false" data-prefix="fal" data-icon="timer" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" data-fa-i2svg="">
    <path
      fill="currentColor"
      d="M512 256C512 397.4 397.4 512 256 512C114.6 512 0 397.4 0 256C0 202.7 16.28 153.2 44.15 112.2C49.12 104.9 59.07 103 66.37 108C73.68 112.1 75.58 122.9 70.61 130.2C46.24 166.1 32 209.4 32 256C32 379.7 132.3 480 256 480C379.7 480 480 379.7 480 256C480 137.7 388.2 40.77 272 32.56V112C272 120.8 264.8 128 256 128C247.2 128 240 120.8 240 112V16C240 7.164 247.2 0 256 0C397.4 0 512 114.6 512 256V256zM267.3 244.7C273.6 250.9 273.6 261.1 267.3 267.3C261.1 273.6 250.9 273.6 244.7 267.3L148.7 171.3C142.4 165.1 142.4 154.9 148.7 148.7C154.9 142.4 165.1 142.4 171.3 148.7L267.3 244.7z"
    ></path>
  </Svg>
);
export const QuestionIcon = () => (
  <Svg className="svg-inline--fa fa-circle-question" aria-hidden="true" focusable="false" data-prefix="fal" data-icon="circle-question" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" data-fa-i2svg="">
    <path
      fill="currentColor"
      d="M256 0C114.6 0 0 114.6 0 256s114.6 256 256 256s256-114.6 256-256S397.4 0 256 0zM256 480c-123.5 0-224-100.5-224-224s100.5-224 224-224s224 100.5 224 224S379.5 480 256 480zM240 344c-13.25 0-24 10.75-24 24s10.75 24 24 24s24-10.75 24-24S253.3 344 240 344zM285.6 128H226.4C189.8 128 160 157.8 160 194.4V204c0 8.844 7.156 16 16 16S192 212.8 192 204V194.4C192 175.4 207.4 160 226.4 160h59.25C304.6 160 320 175.4 320 194.4c0 12.48-6.781 24-17.06 29.72L242.5 254.3C231.1 260.7 224 272.7 224 285.8V304C224 312.8 231.2 320 240 320S256 312.8 256 304V285.8c0-1.453 .7813-2.797 1.438-3.172l60.41-30.22C338.9 240.7 352 218.5 352 194.4C352 157.8 322.2 128 285.6 128z"
    ></path>
  </Svg>
);
export const DefaultIcon = () => (
  <Svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 512 512">
    <path fill="currentColor" d="M256 32a224 224 0 1 1 0 448 224 224 0 1 1 0-448zm0 480A256 256 0 1 0 256 0a256 256 0 1 0 0 512z" />
  </Svg>
);
export const LocationIcon = () => (
  <Svg className="svg-inline--fa fa-location-dot" aria-hidden="true" focusable="false" data-prefix="fal" data-icon="location-dot" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512" data-fa-i2svg="">
    <path
      fill="currentColor"
      d="M272 192C272 236.2 236.2 272 192 272C147.8 272 112 236.2 112 192C112 147.8 147.8 112 192 112C236.2 112 272 147.8 272 192zM192 240C218.5 240 240 218.5 240 192C240 165.5 218.5 144 192 144C165.5 144 144 165.5 144 192C144 218.5 165.5 240 192 240zM384 192C384 279.4 267 435 215.7 499.2C203.4 514.5 180.6 514.5 168.3 499.2C116.1 435 0 279.4 0 192C0 85.96 85.96 0 192 0C298 0 384 85.96 384 192H384zM192 32C103.6 32 32 103.6 32 192C32 207.6 37.43 229 48.56 255.4C59.47 281.3 74.8 309.4 92.14 337.5C126.2 392.8 166.6 445.7 192 477.6C217.4 445.7 257.8 392.8 291.9 337.5C309.2 309.4 324.5 281.3 335.4 255.4C346.6 229 352 207.6 352 192C352 103.6 280.4 32 192 32z"
    ></path>
  </Svg>
);
export const TickIcon = () => (
  <Svg className="svg-inline--fa fa-circle-check" aria-hidden="true" focusable="false" data-prefix="fal" data-icon="circle-check" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" data-fa-i2svg="">
    <path fill="currentColor" d="M235.3 331.3C229.1 337.6 218.9 337.6 212.7 331.3L148.7 267.3C142.4 261.1 142.4 250.9 148.7 244.7C154.9 238.4 165.1 238.4 171.3 244.7L224 297.4L340.7 180.7C346.9 174.4 357.1 174.4 363.3 180.7C369.6 186.9 369.6 197.1 363.3 203.3L235.3 331.3zM512 256C512 397.4 397.4 512 256 512C114.6 512 0 397.4 0 256C0 114.6 114.6 0 256 0C397.4 0 512 114.6 512 256zM256 32C132.3 32 32 132.3 32 256C32 379.7 132.3 480 256 480C379.7 480 480 379.7 480 256C480 132.3 379.7 32 256 32z"></path>
  </Svg>
);
export const UploadIcon = () => (
  <Svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M9.00114 8.4395L12.1834 11.621L11.1221 12.6823L9.75114 11.3113V15.5H8.25114V11.3097L6.88014 12.6823L5.81889 11.621L9.00114 8.4395ZM9.00114 0.5C10.2889 0.500061 11.5317 0.973335 12.4933 1.82984C13.4549 2.68634 14.0682 3.86635 14.2166 5.1455C15.1498 5.39999 15.9639 5.97433 16.5166 6.76815C17.0693 7.56196 17.3255 8.52477 17.2404 9.48829C17.1552 10.4518 16.7342 11.3548 16.0508 12.0394C15.3675 12.7239 14.4653 13.1466 13.5019 13.2335V11.723C13.847 11.6737 14.1789 11.5562 14.4781 11.3772C14.7773 11.1983 15.0379 10.9615 15.2446 10.6808C15.4513 10.4001 15.6 10.081 15.682 9.74213C15.7641 9.40329 15.7778 9.0515 15.7224 8.7073C15.667 8.3631 15.5436 8.03338 15.3594 7.73739C15.1752 7.4414 14.9339 7.18506 14.6495 6.98335C14.3652 6.78164 14.0435 6.63859 13.7033 6.56256C13.363 6.48652 13.0111 6.47902 12.6679 6.5405C12.7853 5.99366 12.779 5.42748 12.6493 4.88341C12.5196 4.33935 12.2699 3.83119 11.9183 3.39615C11.5668 2.96111 11.1224 2.6102 10.6177 2.36915C10.1131 2.12809 9.56082 2.00298 9.00152 2.00298C8.44221 2.00298 7.88998 2.12809 7.38529 2.36915C6.88059 2.6102 6.43621 2.96111 6.08469 3.39615C5.73317 3.83119 5.48342 4.33935 5.35373 4.88341C5.22403 5.42748 5.21768 5.99366 5.33514 6.5405C4.65088 6.412 3.9436 6.56059 3.3689 6.95357C2.79419 7.34655 2.39914 7.95174 2.27064 8.636C2.14214 9.32026 2.29073 10.0275 2.68371 10.6022C3.0767 11.1769 3.68188 11.572 4.36614 11.7005L4.50114 11.723V13.2335C3.53773 13.1467 2.63545 12.7242 1.95199 12.0396C1.26854 11.3551 0.847387 10.4522 0.762147 9.48862C0.676907 8.52507 0.933001 7.56221 1.48567 6.76833C2.03834 5.97445 2.85242 5.40004 3.78564 5.1455C3.93392 3.86628 4.5472 2.68618 5.50883 1.82965C6.47046 0.973115 7.71336 0.499897 9.00114 0.5Z"
      fill="currentColor"
    />
  </Svg>
);
export const ExternalIcon = () => (
  <Svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
    <path fill="currentColor" d="M336 0c-8.8 0-16 7.2-16 16s7.2 16 16 16H457.4L212.7 276.7c-6.2 6.2-6.2 16.4 0 22.6s16.4 6.2 22.6 0L480 54.6V176c0 8.8 7.2 16 16 16s16-7.2 16-16V16c0-8.8-7.2-16-16-16H336zM64 64C28.7 64 0 92.7 0 128V448c0 35.3 28.7 64 64 64H384c35.3 0 64-28.7 64-64V304c0-8.8-7.2-16-16-16s-16 7.2-16 16V448c0 17.7-14.3 32-32 32H64c-17.7 0-32-14.3-32-32V128c0-17.7 14.3-32 32-32H208c8.8 0 16-7.2 16-16s-7.2-16-16-16H64z" />
  </Svg>
);
export const DownIcon = ({ className }) => (
  <Svg className={className} fill="currentColor" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
    <path fill="currentColor" d="M199 401c9.4 9.4 24.6 9.4 33.9 0L425 209c9.4-9.4 9.4-24.6 0-33.9s-24.6-9.4-33.9 0l-175 175L41 175c-9.4-9.4-24.6-9.4-33.9 0s-9.4 24.6 0 33.9L199 401z" />
  </Svg>
);
export const UpIcon = ({ className }) => (
  <Svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 512 512">
    <path d="M244.7 116.7c6.2-6.2 16.4-6.2 22.6 0l192 192c6.2 6.2 6.2 16.4 0 22.6s-16.4 6.2-22.6 0L256 150.6 75.3 331.3c-6.2 6.2-16.4 6.2-22.6 0s-6.2-16.4 0-22.6l192-192z" />{" "}
  </Svg>
);
export const NextIcon = () => (
  <Svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512">
    <path fill="currentColor" d="M305 239c9.4 9.4 9.4 24.6 0 33.9L113 465c-9.4 9.4-24.6 9.4-33.9 0s-9.4-24.6 0-33.9l175-175L79 81c-9.4-9.4-9.4-24.6 0-33.9s24.6-9.4 33.9 0L305 239z" />
  </Svg>
);
export const PreviousIcon = () => (
  <Svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512">
    <path fill="currentColor" d="M9.4 233.4c-12.5 12.5-12.5 32.8 0 45.3l192 192c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L77.3 256 246.6 86.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0l-192 192z" />
  </Svg>
);
export const DeleteIcon = () => (
  <Svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
    <path fill="currentColor" d="M147.2 0H160 288h12.8L308 10.7 354.2 80h13.7H416h32v48H412.4L384 512H64L35.6 128H0V80H32 80.1 93.8L140 10.7 147.2 0zm4.4 80h145L275.2 48H172.8L151.5 80zM83.7 128l24.9 336H339.4l24.9-336H83.7z" />
  </Svg>
);
export const AddIcon = () => (
  <Svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
    <path fill="currentColor" d="M248 72V48H200V72 232H40 16v48H40 200V440v24h48V440 280H408h24V232H408 248V72z" />
  </Svg>
);
export const EditIcon = () => (
  <Svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
    <path
      fill="currentColor"
      d="M454.6 45.3l12.1 12.1c12.5 12.5 12.5 32.8 0 45.3L440 129.4 382.6 72l26.7-26.7c12.5-12.5 32.8-12.5 45.3 0zM189 265.6l171-171L417.4 152l-171 171c-4.2 4.2-9.6 7.2-15.4 8.6l-65.6 15.1L180.5 281c1.3-5.8 4.3-11.2 8.6-15.4zm197.7-243L166.4 243c-8.5 8.5-14.4 19.2-17.1 30.9l-20.9 90.6c-1.2 5.4 .4 11 4.3 14.9s9.5 5.5 14.9 4.3l90.6-20.9c11.7-2.7 22.4-8.6 30.9-17.1L489.4 125.3c25-25 25-65.5 0-90.5L477.3 22.6c-25-25-65.5-25-90.5 0zM80 64C35.8 64 0 99.8 0 144V432c0 44.2 35.8 80 80 80H368c44.2 0 80-35.8 80-80V304c0-8.8-7.2-16-16-16s-16 7.2-16 16V432c0 26.5-21.5 48-48 48H80c-26.5 0-48-21.5-48-48V144c0-26.5 21.5-48 48-48H208c8.8 0 16-7.2 16-16s-7.2-16-16-16H80z"
    />
  </Svg>
);
export const FilterIcon = () => (
  <Svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 14 10" fill="currentColor">
    <path d="M5.5 9.5H8.5V8H5.5V9.5ZM0.25 0.5V2H13.75V0.5H0.25ZM2.5 5.75H11.5V4.25H2.5V5.75Z" fill="currentColor" />
  </Svg>
);
export const CallIcon = () => (
  <Svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
    <path
      fill="currentColor"
      d="M375.8 275.2c-16.4-7-35.4-2.4-46.7 11.4l-33.2 40.6c-46-26.7-84.4-65.1-111.1-111.1L225.3 183c13.8-11.3 18.5-30.3 11.4-46.7l-48-112C181.2 6.7 162.3-3.1 143.6 .9l-112 24C13.2 28.8 0 45.1 0 64v0C0 300.7 183.5 494.5 416 510.9c4.5 .3 9.1 .6 13.7 .8c0 0 0 0 0 0c0 0 0 0 .1 0c6.1 .2 12.1 .4 18.3 .4l0 0c18.9 0 35.2-13.2 39.1-31.6l24-112c4-18.7-5.8-37.6-23.4-45.1l-112-48zM447.7 480C218.1 479.8 32 293.7 32 64v0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0c0-3.8 2.6-7 6.3-7.8l112-24c3.7-.8 7.5 1.2 9 4.7l48 112c1.4 3.3 .5 7.1-2.3 9.3l-40.6 33.2c-12.1 9.9-15.3 27.2-7.4 40.8c29.5 50.9 71.9 93.3 122.7 122.7c13.6 7.9 30.9 4.7 40.8-7.4l33.2-40.6c2.3-2.8 6.1-3.7 9.3-2.3l112 48c3.5 1.5 5.5 5.3 4.7 9l-24 112c-.8 3.7-4.1 6.3-7.8 6.3c-.1 0-.2 0-.3 0z"
    />
  </Svg>
);
export const NoteIcon = () => (
  <Svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
    <path fill="currentColor" d="M128 96c0-17.7 14.3-32 32-32H416c17.7 0 32 14.3 32 32V256H368c-26.5 0-48 21.5-48 48v80H160c-17.7 0-32-14.3-32-32V96zM446.2 288c-1.6 4.5-4.2 8.7-7.6 12.1l-74.5 74.5c-3.4 3.4-7.6 6-12.1 7.6V304c0-8.8 7.2-16 16-16h78.2zM96 96V352c0 35.3 28.7 64 64 64H341.5c17 0 33.3-6.7 45.3-18.7l74.5-74.5c12-12 18.7-28.3 18.7-45.3V96c0-35.3-28.7-64-64-64H160c-35.3 0-64 28.7-64 64zM320 496c0-8.8-7.2-16-16-16H128c-53 0-96-43-96-96l0-240c0-8.8-7.2-16-16-16s-16 7.2-16 16V384c0 70.7 57.3 128 128 128H304c8.8 0 16-7.2 16-16z" />
  </Svg>
);
export const NotificationIcon = () => (
  <Svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 448 512">
    <path
      fill="currentColor"
      d="M208 16c0-8.8 7.2-16 16-16s16 7.2 16 16V32.8c80.9 8 144 76.2 144 159.2v29.1c0 43.7 17.4 85.6 48.3 116.6l2.8 2.8c8.3 8.3 13 19.6 13 31.3c0 24.5-19.8 44.3-44.3 44.3H44.3C19.8 416 0 396.2 0 371.7c0-11.7 4.7-23 13-31.3l2.8-2.8C46.6 306.7 64 264.8 64 221.1V192c0-83 63.1-151.2 144-159.2V16zm16 48C153.3 64 96 121.3 96 192v29.1c0 52.2-20.7 102.3-57.7 139.2L35.6 363c-2.3 2.3-3.6 5.4-3.6 8.7c0 6.8 5.5 12.3 12.3 12.3H403.7c6.8 0 12.3-5.5 12.3-12.3c0-3.3-1.3-6.4-3.6-8.7l-2.8-2.8c-36.9-36.9-57.7-87-57.7-139.2V192c0-70.7-57.3-128-128-128zM193.8 458.7c4.4 12.4 16.3 21.3 30.2 21.3s25.8-8.9 30.2-21.3c2.9-8.3 12.1-12.7 20.4-9.8s12.7 12.1 9.8 20.4C275.6 494.2 251.9 512 224 512s-51.6-17.8-60.4-42.7c-2.9-8.3 1.4-17.5 9.8-20.4s17.5 1.4 20.4 9.8z"
    />
  </Svg>
);
export const SearchIcon = () => (
  <Svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" fill="currentColor">
    <path d="M12.5232 11.4627L15.7355 14.6742L14.6742 15.7355L11.4627 12.5232C10.2678 13.4812 8.7815 14.0022 7.25 14C3.524 14 0.5 10.976 0.5 7.25C0.5 3.524 3.524 0.5 7.25 0.5C10.976 0.5 14 3.524 14 7.25C14.0022 8.7815 13.4812 10.2678 12.5232 11.4627ZM11.0187 10.9062C11.9706 9.92741 12.5021 8.61532 12.5 7.25C12.5 4.349 10.1502 2 7.25 2C4.349 2 2 4.349 2 7.25C2 10.1502 4.349 12.5 7.25 12.5C8.61532 12.5021 9.92741 11.9706 10.9062 11.0187L11.0187 10.9062Z" fill="currentColor" transform="scale(0.9)" />
  </Svg>
);
export const MenuIcon = () => (
  <Svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
    <path fill="currentColor" d="M0 80c0-8.8 7.2-16 16-16H432c8.8 0 16 7.2 16 16s-7.2 16-16 16H16C7.2 96 0 88.8 0 80zM0 240c0-8.8 7.2-16 16-16H432c8.8 0 16 7.2 16 16s-7.2 16-16 16H16c-8.8 0-16-7.2-16-16zM448 400c0 8.8-7.2 16-16 16H16c-8.8 0-16-7.2-16-16s7.2-16 16-16H432c8.8 0 16 7.2 16 16z" />
  </Svg>
);
export const UserIcon = () => (
  <Svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
    <path fill="currentColor" d="M320 128a96 96 0 1 0 -192 0 96 96 0 1 0 192 0zM96 128a128 128 0 1 1 256 0A128 128 0 1 1 96 128zM32 480H416c-1.2-79.7-66.2-144-146.3-144H178.3c-80 0-145 64.3-146.3 144zM0 482.3C0 383.8 79.8 304 178.3 304h91.4C368.2 304 448 383.8 448 482.3c0 16.4-13.3 29.7-29.7 29.7H29.7C13.3 512 0 498.7 0 482.3z" />
  </Svg>
);
export const SettingsIcon = () => (
  <Svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
    <path
      fill="currentColor"
      d="M195 108c-13.2 5.4-25.5 12.6-36.7 21.2l-13.3 10.2-16-5.1-52-16.6L46.9 170.2l40.3 36.7 12.4 11.3-2.2 16.6c-.9 6.9-1.4 14-1.4 21.2s.5 14.3 1.4 21.2l2.2 16.6L87.2 305.1 46.9 341.8l30.2 52.4 52-16.6 16-5.1 13.3 10.2c11.2 8.6 23.5 15.8 36.7 21.2l15.5 6.4 3.6 16.4L225.8 480h60.5l11.7-53.3 3.6-16.4L317 404c13.2-5.4 25.5-12.6 36.7-21.2l13.3-10.2 16 5.1 52 16.6 30.2-52.4-40.3-36.7-12.4-11.3 2.2-16.6c.9-6.9 1.4-14 1.4-21.2s-.5-14.3-1.4-21.2l-2.2-16.6 12.4-11.3 40.3-36.7-30.2-52.4-52 16.6-16 5.1-13.3-10.2c-11.2-8.6-23.5-15.8-36.7-21.2l-15.5-6.4-3.6-16.4L286.2 32H225.8L214.1 85.3l-3.6 16.4L195 108zM200 0H312l17.2 78.4c15.8 6.5 30.6 15.1 44 25.4l76.5-24.4 56 97-59.4 54.1c1.1 8.3 1.7 16.8 1.7 25.4s-.6 17.1-1.7 25.4l59.4 54.1-56 97-76.5-24.4c-13.4 10.3-28.2 18.9-44 25.4L312 512H200l-17.2-78.4c-15.8-6.5-30.6-15.1-44-25.4L62.3 432.5l-56-97 59.4-54.1C64.6 273.1 64 264.6 64 256s.6-17.1 1.7-25.4L6.3 176.5l56-97 76.5 24.4c13.4-10.3 28.2-18.9 44-25.4L200 0zM312 256a56 56 0 1 0 -112 0 56 56 0 1 0 112 0zm-144 0a88 88 0 1 1 176 0 88 88 0 1 1 -176 0z"
    />
  </Svg>
);
export const UserRoleIcon = () => (
  <Svg className="svg-inline--fa fa-house-blank" aria-hidden="true" focusable="false" data-prefix="far" data-icon="house-blank" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512" data-fa-i2svg="">
    <path
      fill="currentColor"
      d="M224 224a96 96 0 1 0 0-192 96 96 0 1 0 0 192zM32 480H322.2l-1 3.8c-2.4 9.5-1.8 19.3 1.4 28.2H29.7C13.3 512 0 498.7 0 482.3C0 383.8 79.8 304 178.3 304h91.4c44.2 0 84.6 16.1 115.8 42.7l-22.7 22.7c-25.3-20.9-57.7-33.4-93.1-33.4H178.3c-80 0-145 64.3-146.3 144zM96 128a128 128 0 1 1 256 0A128 128 0 1 1 96 128zM582.8 264c-6.2-6.2-16.4-6.2-22.6 0l-24.8 24.8 40 40L600.2 304c6.2-6.2 6.2-16.4 0-22.6L582.8 264zM406.5 417.7c-2.1 2.1-3.5 4.6-4.2 7.4l-12.3 49 49-12.3c2.8-.7 5.4-2.2 7.4-4.2L552.7 351.4l-40-40L406.5 417.7zM537.5 241.4c18.7-18.7 49.1-18.7 67.9 0l17.4 17.4c18.7 18.7 18.7 49.1 0 67.9L469.1 480.3c-6.2 6.2-13.9 10.5-22.3 12.6l-74.9 18.7c-5.5 1.4-11.2-.2-15.2-4.2s-5.6-9.7-4.2-15.2l18.7-74.9c2.1-8.4 6.5-16.1 12.6-22.3L537.5 241.4z"
    />
  </Svg>
);
export const UserListIcon = () => (
  <Svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512">
    <path fill="currentColor" d="M128 128a96 96 0 1 1 192 0 96 96 0 1 1 -192 0zM269.7 336c80 0 145 64.3 146.3 144H32c1.2-79.7 66.2-144 146.3-144h91.4zM224 256A128 128 0 1 0 224 0a128 128 0 1 0 0 256zm-45.7 48C79.8 304 0 383.8 0 482.3C0 498.7 13.3 512 29.7 512H418.3c16.4 0 29.7-13.3 29.7-29.7C448 383.8 368.2 304 269.7 304H178.3zm457-116.7c6.2-6.2 6.2-16.4 0-22.6s-16.4-6.2-22.6 0L496 281.4l-52.7-52.7c-6.2-6.2-16.4-6.2-22.6 0s-6.2 16.4 0 22.6l64 64c6.2 6.2 16.4 6.2 22.6 0l128-128z" />
  </Svg>
);
export const FranchiseIcon = () => (
  <Svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512">
    <path
      fill="currentColor"
      d="M520.6 120.9l0 0c18.7 29.6 .3 66.6-29.2 70.6c-2.6 .3-5.2 .5-7.9 .5c-16.5 0-31.2-7.2-41.5-18.5c-6.1-6.7-14.7-10.5-23.7-10.5s-17.6 3.8-23.7 10.5c-10.2 11.2-24.8 18.4-41.4 18.4c-16.5 0-31.3-7.2-41.4-18.4c-6.1-6.7-14.7-10.5-23.7-10.5s-17.7 3.8-23.7 10.5C254 184.8 239.3 192 222.7 192c-16.5 0-31.3-7.2-41.4-18.4c-6.1-6.7-14.7-10.5-23.7-10.5s-17.7 3.8-23.7 10.5C123.6 184.8 109 192 92.4 192c-2.6 0-5.2-.2-7.9-.5c-29.3-4-47.7-41-29.1-70.6l0 0L111.6 32H464.4l56.2 88.9zM483.4 224c4.1 0 8.1-.3 12.1-.8c55.5-7.4 81.8-72.5 52.1-119.4L490.3 13.1C485.2 5 476.1 0 466.4 0H109.6C99.9 0 90.8 5 85.7 13.1L28.3 103.8c-29.6 46.8-3.4 111.9 51.9 119.4c4 .5 8.1 .8 12.1 .8c19.6 0 37.5-6.4 52-17c4.8-3.5 9.2-7.6 13.2-11.9c4 4.4 8.4 8.4 13.2 11.9c14.5 10.6 32.4 17 52 17c19.6 0 37.5-6.4 52-17c4.8-3.5 9.2-7.6 13.2-12c4 4.4 8.4 8.4 13.2 11.9c14.5 10.6 32.4 17 52 17c19.8 0 37.8-6.5 52.3-17.3c4.7-3.5 9-7.4 12.9-11.7c3.9 4.3 8.3 8.3 13 11.8c14.5 10.7 32.5 17.2 52.2 17.2zM80 256c-8.8 0-16 7.2-16 16v80 16 16 64c0 35.3 28.7 64 64 64H448c35.3 0 64-28.7 64-64V384 368 352 272c0-8.8-7.2-16-16-16s-16 7.2-16 16v80H96V272c0-8.8-7.2-16-16-16zM96 384H480v64c0 17.7-14.3 32-32 32H128c-17.7 0-32-14.3-32-32V384z"
    />
  </Svg>
);
export const FranchiseListIcon = () => (
  <Svg>
    <path
      fill="currentColor"
      d="M520.6,120.9L520.6,120.9c18.7,29.6,0.3,66.6-29.2,70.6c-2.6,0.3-5.2,0.5-7.9,0.5c-16.5,0-31.2-7.2-41.5-18.5c-6.1-6.7-14.7-10.5-23.7-10.5s-17.6,3.8-23.7,10.5c-10.2,11.2-24.8,18.4-41.4,18.4c-16.5,0-31.3-7.2-41.4-18.4c-6.1-6.7-14.7-10.5-23.7-10.5s-17.7,3.8-23.7,10.5C254,184.8,239.3,192,222.7,192c-16.5,0-31.3-7.2-41.4-18.4c-6.1-6.7-14.7-10.5-23.7-10.5s-17.7,3.8-23.7,10.5C123.6,184.8,109,192,92.4,192c-2.6,0-5.2-0.2-7.9-0.5c-29.3-4-47.7-41-29.1-70.6l0,0L111.6,32h352.8L520.6,120.9z M483.4,224c4.1,0,8.1-0.3,12.1-0.8c55.5-7.4,81.8-72.5,52.1-119.4l-57.3-90.7C485.2,5,476.1,0,466.4,0H109.6c-9.7,0-18.8,5-23.9,13.1l-57.4,90.7c-29.6,46.8-3.4,111.9,51.9,119.4c4,0.5,8.1,0.8,12.1,0.8c19.6,0,37.5-6.4,52-17c4.8-3.5,9.2-7.6,13.2-11.9c4,4.4,8.4,8.4,13.2,11.9c14.5,10.6,32.4,17,52,17s37.5-6.4,52-17c4.8-3.5,9.2-7.6,13.2-12c4,4.4,8.4,8.4,13.2,11.9c14.5,10.6,32.4,17,52,17c19.8,0,37.8-6.5,52.3-17.3c4.7-3.5,9-7.4,12.9-11.7c3.9,4.3,8.3,8.3,13,11.8c14.5,10.7,32.5,17.2,52.2,17.2L483.4,224z M80,256c-8.8,0-16,7.2-16,16v80v16v16v64c0,35.3,28.7,64,64,64h320c35.3,0,64-28.7,64-64v-64v-16v-16v-80c0-8.8-7.2-16-16-16s-16,7.2-16,16v80H96v-80C96,263.2,88.8,256,80,256z M96,384h384v64c0,17.7-14.3,32-32,32H128c-17.7,0-32-14.3-32-32V384z"
    />
    <path fill="currentColor" d="M639,376c-17.7,0-32,14.3-32,32s14.3,32,32,32s32-14.3,32-32S656.7,376,639,376z M639,216c-17.7,0-32,14.3-32,32s14.3,32,32,32s32-14.3,32-32S656.7,216,639,216z M671,88c0-17.7-14.3-32-32-32s-32,14.3-32,32s14.3,32,32,32S671,105.7,671,88z" />
  </Svg>
);
export const ExcelIcon = () => (
  <Svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 576 512">
    <path fill="currentColor" d="M352 448c0 17.7-14.3 32-32 32H64c-17.7 0-32-14.3-32-32V64c0-17.7 14.3-32 32-32H192V144c0 26.5 21.5 48 48 48H352v64h32V163.9c0-12.7-5.1-24.9-14.1-33.9L254.1 14.1c-9-9-21.2-14.1-33.9-14.1H64C28.7 0 0 28.7 0 64V448c0 35.3 28.7 64 64 64H320c35.3 0 64-28.7 64-64V368H352v80zm-.5-288H240c-8.8 0-16-7.2-16-16V32.5c2.8 .7 5.4 2.1 7.4 4.2L347.3 152.6c2.1 2.1 3.5 4.6 4.2 7.4zm123.8 52.7c-6.2-6.2-16.4-6.2-22.6 0s-6.2 16.4 0 22.6L521.4 304H208c-8.8 0-16 7.2-16 16s7.2 16 16 16H521.4l-68.7 68.7c-6.2 6.2-6.2 16.4 0 22.6s16.4 6.2 22.6 0l96-96c6.2-6.2 6.2-16.4 0-22.6l-96-96z" />
  </Svg>
);
export const Dots = () => (
  <Svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="3" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-ellipsis-vertical">
    <circle cx="12" cy="12" r="1" />
    <circle cx="12" cy="5" r="1" />
    <circle cx="12" cy="19" r="1" />
  </Svg>
);
export const ReloadIcon = () => (
  <Svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
    <path fill="currentColor" d="M417.3 360.2c3.2-5 8.7-8.2 14.7-8.2c12.3 0 20.3 12.8 13.7 23.2C406 438.2 335.9 480 256 480C132.3 480 32 379.7 32 256S132.3 32 256 32c56.1 0 107.4 20.6 146.7 54.7L433.4 56c5.1-5.1 12.1-8 19.3-8C467.8 48 480 60.2 480 75.3V176c0 8.8-7.2 16-16 16H363.3c-15.1 0-27.3-12.2-27.3-27.3c0-7.2 2.9-14.2 8-19.3l36-36C346.5 81.1 303.3 64 256 64C150 64 64 150 64 256s86 192 192 192c67.6 0 127.1-35 161.3-87.8zM448 86.6L374.6 160H448V86.6z" />
  </Svg>
);
export const PlainTickIcon = () => (
  <Svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
    <path fill="currentColor" d="M443.3 100.7c6.2 6.2 6.2 16.4 0 22.6l-272 272c-6.2 6.2-16.4 6.2-22.6 0l-144-144c-6.2-6.2-6.2-16.4 0-22.6s16.4-6.2 22.6 0L160 361.4 420.7 100.7c6.2-6.2 16.4-6.2 22.6 0z" />
  </Svg>
);
export const ReplaceIcon = () => (
  <Svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 640 512">
    <path
      fill="currentColor"
      d="M64 96H512c17.7 0 32 14.3 32 32v69.6c10.2-4 21.1-5.9 32-5.6V128c0-35.3-28.7-64-64-64H64C28.7 64 0 92.7 0 128V384c0 35.3 28.7 64 64 64H330.2l8-32H64c-17.7 0-32-14.3-32-32V128c0-17.7 14.3-32 32-32zm48 192c-8.8 0-16 7.2-16 16s7.2 16 16 16H368c8.8 0 16-7.2 16-16s-7.2-16-16-16H112zM96 208c0 8.8 7.2 16 16 16H464c8.8 0 16-7.2 16-16s-7.2-16-16-16H112c-8.8 0-16 7.2-16 16zm486.8 56l17.4 17.4c6.2 6.2 6.2 16.4 0 22.6l-24.8 24.8-40-40L560.2 264c6.2-6.2 16.4-6.2 22.6 0zM406.5 417.7L512.7 311.5l40 40L446.4 457.7c-2.1 2.1-4.6 3.5-7.4 4.2l-49 12.3 12.3-49c.7-2.8 2.2-5.4 4.2-7.4zM537.5 241.4L383.8 395.1c-6.2 6.2-10.5 13.9-12.6 22.3l-18.7 74.9c-1.4 5.5 .2 11.2 4.2 15.2s9.7 5.6 15.2 4.2L446.8 493c8.4-2.1 16.1-6.5 22.3-12.6L622.8 326.6c18.7-18.7 18.7-49.1 0-67.9l-17.4-17.4c-18.7-18.7-49.1-18.7-67.9 0z"
    />
  </Svg>
);
export const DashboardIcon = () => (
  <Svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 448 512">
    <path
      fill="currentColor"
      d="M48 64c-8.8 0-16 7.2-16 16V240c0 8.8 7.2 16 16 16h96c8.8 0 16-7.2 16-16V80c0-8.8-7.2-16-16-16H48zM0 80C0 53.5 21.5 32 48 32h96c26.5 0 48 21.5 48 48V240c0 26.5-21.5 48-48 48H48c-26.5 0-48-21.5-48-48V80zM304 256c-8.8 0-16 7.2-16 16V432c0 8.8 7.2 16 16 16h96c8.8 0 16-7.2 16-16V272c0-8.8-7.2-16-16-16H304zm-48 16c0-26.5 21.5-48 48-48h96c26.5 0 48 21.5 48 48V432c0 26.5-21.5 48-48 48H304c-26.5 0-48-21.5-48-48V272zM144 352H48c-8.8 0-16 7.2-16 16v64c0 8.8 7.2 16 16 16h96c8.8 0 16-7.2 16-16V368c0-8.8-7.2-16-16-16zM48 320h96c26.5 0 48 21.5 48 48v64c0 26.5-21.5 48-48 48H48c-26.5 0-48-21.5-48-48V368c0-26.5 21.5-48 48-48zM304 64c-8.8 0-16 7.2-16 16v64c0 8.8 7.2 16 16 16h96c8.8 0 16-7.2 16-16V80c0-8.8-7.2-16-16-16H304zM256 80c0-26.5 21.5-48 48-48h96c26.5 0 48 21.5 48 48v64c0 26.5-21.5 48-48 48H304c-26.5 0-48-21.5-48-48V80z"
    />
  </Svg>
);
export const AdmissionHistory = () => (
  <Svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 640 512">
    <path
      fill="currentColor"
      d="M480 192v.7c5.3-.5 10.6-.7 16-.7s10.7 .2 16 .7V192c0-35.3-28.7-64-64-64L64 128c-35.3 0-64 28.7-64 64V448c0 35.3 28.7 64 64 64l330.8 0c-12.9-9.1-24.5-19.9-34.6-32L64 480c-17.7 0-32-14.3-32-32l0-256c0-17.7 14.3-32 32-32l384 0c17.7 0 32 14.3 32 32zM448 64L64 64c-8.8 0-16 7.2-16 16s7.2 16 16 16l384 0c8.8 0 16-7.2 16-16s-7.2-16-16-16zM400 0H112c-8.8 0-16 7.2-16 16s7.2 16 16 16l288 0c8.8 0 16-7.2 16-16s-7.2-16-16-16zM384 368a112 112 0 1 1 224 0 112 112 0 1 1 -224 0zm256 0a144 144 0 1 0 -288 0 144 144 0 1 0 288 0zM512 304c0-8.8-7.2-16-16-16s-16 7.2-16 16v48H432c-8.8 0-16 7.2-16 16s7.2 16 16 16h48v48c0 8.8 7.2 16 16 16s16-7.2 16-16V384h48c8.8 0 16-7.2 16-16s-7.2-16-16-16H512V304z"
    />
  </Svg>
);
export const EnlargeIcon = () => (
  <Svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 512 512">
    <path fill="currentColor" d="M292.7 196.7c-6.2 6.2-6.2 16.4 0 22.6s16.4 6.2 22.6 0L480 54.6V160c0 8.8 7.2 16 16 16s16-7.2 16-16V16c0-8.8-7.2-16-16-16H352c-8.8 0-16 7.2-16 16s7.2 16 16 16H457.4L292.7 196.7zM219.3 315.3c6.2-6.2 6.2-16.4 0-22.6s-16.4-6.2-22.6 0L32 457.4V352c0-8.8-7.2-16-16-16s-16 7.2-16 16V496c0 8.8 7.2 16 16 16H160c8.8 0 16-7.2 16-16s-7.2-16-16-16H54.6L219.3 315.3z" />
  </Svg>
);
export const PrintIcon = () => (
  <Svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 512 512">
    <path fill="currentColor" d="M96 160H64V64C64 28.7 92.7 0 128 0H357.5c17 0 33.3 6.7 45.3 18.7l26.5 26.5c12 12 18.7 28.3 18.7 45.3V160H416V90.5c0-8.5-3.4-16.6-9.4-22.6L380.1 41.4c-6-6-14.1-9.4-22.6-9.4H128c-17.7 0-32 14.3-32 32v96zm352 64H64c-17.7 0-32 14.3-32 32V384H64V352c0-17.7 14.3-32 32-32H416c17.7 0 32 14.3 32 32v32h32V256c0-17.7-14.3-32-32-32zm0 192v64c0 17.7-14.3 32-32 32H96c-17.7 0-32-14.3-32-32V416H32c-17.7 0-32-14.3-32-32V256c0-35.3 28.7-64 64-64H448c35.3 0 64 28.7 64 64V384c0 17.7-14.3 32-32 32H448zM96 352l0 128H416V352H96zM432 248a24 24 0 1 1 0 48 24 24 0 1 1 0-48z" />
  </Svg>
);
export const InfoIcon = () => (
  <Svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 512 512">
    <path fill="currentColor" d="M256 32a224 224 0 1 1 0 448 224 224 0 1 1 0-448zm0 480A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM208 352c-8.8 0-16 7.2-16 16s7.2 16 16 16h96c8.8 0 16-7.2 16-16s-7.2-16-16-16H272V240c0-8.8-7.2-16-16-16H216c-8.8 0-16 7.2-16 16s7.2 16 16 16h24v96H208zm48-168a24 24 0 1 0 0-48 24 24 0 1 0 0 48z" />
  </Svg>
);
export const RestoreIcon = () => (
  <Svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 448 512">
    <path
      fill="currentColor"
      d="M164.2 39.5L148.9 64H299.1L283.8 39.5c-2.9-4.7-8.1-7.5-13.6-7.5H177.7c-5.5 0-10.6 2.8-13.6 7.5zM311 22.6L336.9 64H384h32 16c8.8 0 16 7.2 16 16s-7.2 16-16 16H416V432c0 44.2-35.8 80-80 80H112c-44.2 0-80-35.8-80-80V96H16C7.2 96 0 88.8 0 80s7.2-16 16-16H32 64h47.1L137 22.6C145.8 8.5 161.2 0 177.7 0h92.5c16.6 0 31.9 8.5 40.7 22.6zM64 96V432c0 26.5 21.5 48 48 48H336c26.5 0 48-21.5 48-48V96H64zm155.9 93.3c5.9 6.6 5.4 16.7-1.2 22.6l-49 44.1H240c44.2 0 80 35.8 80 80v40c0 8.8-7.2 16-16 16s-16-7.2-16-16V336c0-26.5-21.5-48-48-48H169.7l49 44.1c6.6 5.9 7.1 16 1.2 22.6s-16 7.1-22.6 1.2l-80-72c-3.4-3-5.3-7.4-5.3-11.9s1.9-8.9 5.3-11.9l80-72c6.6-5.9 16.7-5.4 22.6 1.2zM222 185.9c-8.1 1.1-21.6 4.5-23.6 15.1c-1.7 8.7-.2 15.1 34 24.1c0 0 13.2 3.5 16.6 4.4c8.2-1.1 21.6-4.5 23.6-15c2.3-11.9-5.4-16.8-40.9-26c0 0-8.8-2.3-9.7-2.6z"
    />
  </Svg>
);
export const ErrorIcon = () => (
  <Svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 512 512">
    <path
      fill="currentColor"
      d="M128 416v48c0 8.8-7.2 16-16 16H48c-8.8 0-16-7.2-16-16V416h96zm-16 96c12.3 0 23.5-4.6 32-12.2c8.5 7.6 19.7 12.2 32 12.2h64c26.5 0 48-21.5 48-48V416 400 384 193.8l51.4 198.1 4 15.5 18 69.2c6.6 25.5 32 40.6 56.7 33.8l59.6-16.5c24.7-6.8 39.3-33 32.7-58.5l-13.9-53.7-4-15.5-63.9-246-4-15.5-18-69.2C400 9.9 374.6-5.2 349.9 1.6L290.3 18.1c-3.5 1-6.8 2.3-9.9 4C271.9 8.8 257 0 240 0H176c-12.3 0-23.5 4.6-32 12.2C135.5 4.6 124.3 0 112 0H48C21.5 0 0 21.5 0 48V96v16 16V384v16 16 48c0 26.5 21.5 48 48 48h64zM288 64.8V63.5c.3-7.2 5.1-13 10.8-14.6l59.6-16.5c6.6-1.8 14.8 2 17.2 11l14.1 54.2-87.3 24.2L288.6 68.6c-.3-1.3-.5-2.6-.6-3.8zm-32-.6c0 0 0 0 0 0V96H160V48c0-8.8 7.2-16 16-16h64c8.8 0 16 7.2 16 16V64.2zM176 480c-8.8 0-16-7.2-16-16V416h96v48c0 8.8-7.2 16-16 16H176zM128 128V384H32V128h96zm0-80V96H32V48c0-8.8 7.2-16 16-16h64c8.8 0 16 7.2 16 16zM256 384H160V128h96V384zM397.7 128.7l59.8 230.5-87.3 24.2L310.4 152.8l87.3-24.2zm67.9 261.5l13.8 53.2c2.4 9.4-3.2 17.7-10.3 19.6l-59.6 16.5c-6.6 1.8-14.8-2-17.2-11l-14.1-54.2 87.3-24.2z"
    />
  </Svg>
);
export const ErrorMarkIcon = () => (
  <Svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
    <path fill="currentColor" d="M256 32a224 224 0 1 1 0 448 224 224 0 1 1 0-448zm0 480A256 256 0 1 0 256 0a256 256 0 1 0 0 512zm0-384c-8.8 0-16 7.2-16 16V272c0 8.8 7.2 16 16 16s16-7.2 16-16V144c0-8.8-7.2-16-16-16zm24 224a24 24 0 1 0 -48 0 24 24 0 1 0 48 0z" />
  </Svg>
);
export const AnnouncementIcon = () => (
  <Svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 576 512">
    <path fill="currentColor" d="M560 32c-8.8 0-16 7.2-16 16V59.5L32 187.5V176c0-8.8-7.2-16-16-16s-16 7.2-16 16v19.5V208v96 12.5V336c0 8.8 7.2 16 16 16s16-7.2 16-16V324.5l131.7 32.9c-2.4 8.4-3.7 17.4-3.7 26.6c0 53 43 96 96 96c46.2 0 84.7-32.6 93.9-76L544 452.5V464c0 8.8 7.2 16 16 16s16-7.2 16-16V440 72 48c0-8.8-7.2-16-16-16zM544 419.5L32 291.5v-71l512-128v327zM192 384c0-6.5 1-12.9 2.8-18.8l124 31C313.1 425.7 287.2 448 256 448c-35.3 0-64-28.7-64-64z" />
  </Svg>
);
export const CloneIcon = () => (
  <Svg fill="currentColor" xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 512 512">
    <path fill="currentColor" d="M64 480H288c17.7 0 32-14.3 32-32V384h32v64c0 35.3-28.7 64-64 64H64c-35.3 0-64-28.7-64-64V224c0-35.3 28.7-64 64-64h64v32H64c-17.7 0-32 14.3-32 32V448c0 17.7 14.3 32 32 32zM224 320H448c17.7 0 32-14.3 32-32V64c0-17.7-14.3-32-32-32H224c-17.7 0-32 14.3-32 32V288c0 17.7 14.3 32 32 32zm-64-32V64c0-35.3 28.7-64 64-64H448c35.3 0 64 28.7 64 64V288c0 35.3-28.7 64-64 64H224c-35.3 0-64-28.7-64-64z" />{" "}
  </Svg>
);
export const OpenBook = () => (
  <Svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 512 512">
    <path
      fill="currentColor"
      d="M213.3 128H416V64L213.3 64l-32 32 32 32zM190.6 41.4c6-6 14.1-9.4 22.6-9.4H416c17.7 0 32 14.3 32 32v64c0 17.7-14.3 32-32 32H213.3c-8.5 0-16.6-3.4-22.6-9.4l-43.3-43.3c-6.2-6.2-6.2-16.4 0-22.6l43.3-43.3zM64 128a32 32 0 1 1 0-64 32 32 0 1 1 0 64zm0 160a32 32 0 1 1 0-64 32 32 0 1 1 0 64zM32 416a32 32 0 1 1 64 0 32 32 0 1 1 -64 0zm181.3 32H416V384H213.3l-32 32 32 32zm-22.6-86.6c6-6 14.1-9.4 22.6-9.4H416c17.7 0 32 14.3 32 32v64c0 17.7-14.3 32-32 32H213.3c-8.5 0-16.6-3.4-22.6-9.4l-43.3-43.3c-6.2-6.2-6.2-16.4 0-22.6l43.3-43.3zM181.3 256l32 32H480V224l-266.7 0-32 32zm-33.9-11.3l43.3-43.3c6-6 14.1-9.4 22.6-9.4H480c17.7 0 32 14.3 32 32v64c0 17.7-14.3 32-32 32H213.3c-8.5 0-16.6-3.4-22.6-9.4l-43.3-43.3c-6.2-6.2-6.2-16.4 0-22.6z"
    />
  </Svg>
);
export const PauseIcon = () => (
  <Svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 512 512">
    <path fill="currentColor" d="M480 256A224 224 0 1 0 32 256a224 224 0 1 0 448 0zM0 256a256 256 0 1 1 512 0A256 256 0 1 1 0 256zm224-80V336c0 8.8-7.2 16-16 16s-16-7.2-16-16V176c0-8.8 7.2-16 16-16s16 7.2 16 16zm96 0V336c0 8.8-7.2 16-16 16s-16-7.2-16-16V176c0-8.8 7.2-16 16-16s16 7.2 16 16z" />
  </Svg>
);
export const PlayIcon = () => (
  <Svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 384 512">
    <path fill="currentColor" d="M56.3 66.3c-4.9-3-11.1-3.1-16.2-.3s-8.2 8.2-8.2 14V432c0 5.8 3.1 11.1 8.2 14s11.2 2.7 16.2-.3l288-176c4.8-2.9 7.7-8.1 7.7-13.7s-2.9-10.7-7.7-13.7l-288-176zM24.5 38.1C39.7 29.6 58.2 30 73 39L361 215c14.3 8.7 23 24.2 23 41s-8.7 32.2-23 41L73 473c-14.8 9.1-33.4 9.4-48.5 .9S0 449.4 0 432V80C0 62.6 9.4 46.6 24.5 38.1z" />
  </Svg>
);
export const SwapIcon = () => (
  <Svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 512 512">
    <path
      fill="currentColor"
      d="M427.3 36.7c-6.2-6.2-16.4-6.2-22.6 0s-6.2 16.4 0 22.6L457.4 112H400 344c-20.1 0-39.1 9.5-51.2 25.6L244 202.7l20 26.7 54.4-72.5c6-8.1 15.5-12.8 25.6-12.8h56 57.4l-52.7 52.7c-6.2 6.2-6.2 16.4 0 22.6s16.4 6.2 22.6 0l80-80c6.2-6.2 6.2-16.4 0-22.6l-80-80zM124.8 361.6c-3 4-7.8 6.4-12.8 6.4H16c-8.8 0-16 7.2-16 16s7.2 16 16 16h96c15.1 0 29.3-7.1 38.4-19.2L204 309.3l-20-26.7-59.2 78.9zM404.7 475.3c6.2 6.2 16.4 6.2 22.6 0l80-80c6.2-6.2 6.2-16.4 0-22.6l-80-80c-6.2-6.2-16.4-6.2-22.6 0s-6.2 16.4 0 22.6L457.4 368H400 344c-10.1 0-19.6-4.7-25.6-12.8l-168-224C141.3 119.1 127.1 112 112 112H16c-8.8 0-16 7.2-16 16s7.2 16 16 16h96c5 0 9.8 2.4 12.8 6.4l168 224C304.9 390.5 323.9 400 344 400h56 57.4l-52.7 52.7c-6.2 6.2-6.2 16.4 0 22.6z"
    />
  </Svg>
);
export const RedoIcon = () => (
  <Svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 512 512">
    <path fill="currentColor" d="M417.3 360.2c3.2-5 8.7-8.2 14.7-8.2c12.3 0 20.3 12.8 13.7 23.2C406 438.2 335.9 480 256 480C132.3 480 32 379.7 32 256S132.3 32 256 32c56.1 0 107.4 20.6 146.7 54.7L433.4 56c5.1-5.1 12.1-8 19.3-8C467.8 48 480 60.2 480 75.3V176c0 8.8-7.2 16-16 16H363.3c-15.1 0-27.3-12.2-27.3-27.3c0-7.2 2.9-14.2 8-19.3l36-36C346.5 81.1 303.3 64 256 64C150 64 64 150 64 256s86 192 192 192c67.6 0 127.1-35 161.3-87.8zM448 86.6L374.6 160H448V86.6z" />
  </Svg>
);
export const PageSectionIcon = () => (
  <Svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
    <path
      fill="currentColor"
      d="M0 64C0 28.7 28.7 0 64 0H400c26.5 0 48 21.5 48 48V368c0 20.9-13.4 38.7-32 45.3V480h16c8.8 0 16 7.2 16 16s-7.2 16-16 16H64c-35.3 0-64-28.7-64-64l0 0L0 64zM64 416c-17.7 0-32 14.3-32 32s14.3 32 32 32H384V416H64zM32 392.6c9.4-5.4 20.3-8.6 32-8.6H400c8.8 0 16-7.2 16-16V48c0-8.8-7.2-16-16-16H64C46.3 32 32 46.3 32 64l0 328.6zM224.7 254.1c-20.7-5.5-63.7-17-55.7-58.8c2-10.5 7.5-19.2 15.6-25.8c-11.5-8.8-19.4-21.7-15.6-41.7c5.7-29.8 37.9-45 82-38.7c7.8 1.1 16.8 3.3 28.3 6.9c7.9 2.5 12.3 10.9 9.9 18.8c-2.5 7.9-11 12.3-18.8 9.9c-9.9-3.1-17.5-4.9-23.6-5.8c-26.1-3.8-46 2.3-48.4 14.7c-1.7 8.7-.2 15.1 34 24.1l6.8 1.8 0 0c28.2 7.3 70.9 18.3 62.8 60.6c-2 10.5-7.5 19.2-15.6 25.9c11.8 8.9 19.4 21.7 15.6 41.6c-4.8 25.3-28.9 40.2-63.1 40.2c-6 0-12.3-.5-18.9-1.4c-12.1-1.7-24.9-5.8-36.1-9.5l-5.4-1.8c-7.9-2.5-12.2-11-9.7-18.8c2.5-7.9 11-12.3 18.9-9.7l5.5 1.8c10.1 3.3 21.5 7 31 8.3c26.3 3.7 46.1-2.3 48.4-14.7c2.3-11.9-5.4-16.8-40.9-26l-7-1.8zM222 185.9c-8.1 1.1-21.6 4.5-23.6 15.1c-1.7 8.7-.2 15.1 34 24.1c0 0 13.2 3.5 16.6 4.4c8.2-1.1 21.6-4.5 23.6-15c2.3-11.9-5.4-16.8-40.9-26c0 0-8.8-2.3-9.7-2.6z"
    />
  </Svg>
);
export const SuccessIcon = () => (
  <Svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
    <path
      fill="currentColor"
      d="M190.6 71.4C203 47.9 227.7 32 256 32s53 15.9 65.4 39.4c3.6 6.8 11.5 10.1 18.8 7.8c25.4-7.8 54.1-1.6 74.1 18.4s26.2 48.7 18.4 74.1c-2.3 7.3 1 15.2 7.8 18.8C464.1 203 480 227.7 480 256s-15.9 53-39.4 65.4c-6.8 3.6-10.1 11.5-7.8 18.8c7.8 25.4 1.6 54.1-18.4 74.1s-48.7 26.2-74.1 18.4c-7.3-2.3-15.2 1-18.8 7.8C309 464.1 284.3 480 256 480s-53-15.9-65.4-39.4c-3.6-6.8-11.5-10.1-18.8-7.8c-25.4 7.8-54.1 1.6-74.1-18.4s-26.2-48.7-18.4-74.1c2.3-7.3-1-15.2-7.8-18.8C47.9 309 32 284.3 32 256s15.9-53 39.4-65.4c6.8-3.6 10.1-11.5 7.8-18.8c-7.8-25.4-1.6-54.1 18.4-74.1s48.7-26.2 74.1-18.4c7.3 2.3 15.2-1 18.8-7.8zM256 0c-36.1 0-68 18.1-87.1 45.6c-33-6-68.3 3.8-93.9 29.4s-35.3 60.9-29.4 93.9C18.1 188 0 219.9 0 256s18.1 68 45.6 87.1c-6 33 3.8 68.3 29.4 93.9s60.9 35.3 93.9 29.4C188 493.9 219.9 512 256 512s68-18.1 87.1-45.6c33 6 68.3-3.8 93.9-29.4s35.3-60.9 29.4-93.9C493.9 324 512 292.1 512 256s-18.1-68-45.6-87.1c6-33-3.8-68.3-29.4-93.9s-60.9-35.3-93.9-29.4C324 18.1 292.1 0 256 0zM363.3 203.3c6.2-6.2 6.2-16.4 0-22.6s-16.4-6.2-22.6 0L224 297.4l-52.7-52.7c-6.2-6.2-16.4-6.2-22.6 0s-6.2 16.4 0 22.6l64 64c6.2 6.2 16.4 6.2 22.6 0l128-128z"
    />
  </Svg>
);
export const DeletedIcon = () => (
  <Svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
    <path fill="currentColor" d="M164.2 39.5L148.9 64H299.1L283.8 39.5c-2.9-4.7-8.1-7.5-13.6-7.5H177.7c-5.5 0-10.6 2.8-13.6 7.5zM311 22.6L336.9 64H384h32 16c8.8 0 16 7.2 16 16s-7.2 16-16 16H416V432c0 44.2-35.8 80-80 80H112c-44.2 0-80-35.8-80-80V96H16C7.2 96 0 88.8 0 80s7.2-16 16-16H32 64h47.1L137 22.6C145.8 8.5 161.2 0 177.7 0h92.5c16.6 0 31.9 8.5 40.7 22.6zM64 96V432c0 26.5 21.5 48 48 48H336c26.5 0 48-21.5 48-48V96H64zM331.3 235.3l-128 128c-6.2 6.2-16.4 6.2-22.6 0l-64-64c-6.2-6.2-6.2-16.4 0-22.6s16.4-6.2 22.6 0L192 329.4 308.7 212.7c6.2-6.2 16.4-6.2 22.6 0s6.2 16.4 0 22.6z" />
  </Svg>
);
export const ArrowLeft = () => (
  <Svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 6 10" fill="currentColor">
    <path fill="currentColor" d="M2.20425 4.9992L5.91675 8.7117L4.85625 9.7722L0.083252 4.9992L4.85625 0.226196L5.91675 1.2867L2.20425 4.9992Z" />
  </Svg>
);
export const ArrowDoubleLeft = () => (
  <Svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 10 10" fill="none">
    <path fill="currentColor" d="M0.0249023 5.00057L4.33536 9.31106L5.31751 8.32897L1.98908 5.00057L5.31751 1.67215L4.33536 0.690063L0.0249023 5.00057ZM3.94842 5.00057L8.2589 9.31106L9.24106 8.32897L5.91265 5.00057L9.24106 1.67215L8.2589 0.690063L3.94842 5.00057Z" />
  </Svg>
);
export const ArrowRightIcon = () => (
  <Svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 6 10">
    <path fill="currentColor" d="M3.796 4.9992L0.0834961 1.2867L1.144 0.226196L5.917 4.9992L1.144 9.7722L0.0834961 8.7117L3.796 4.9992Z" />
  </Svg>
);
export const ArrowRightDoubleIcon = () => (
  <Svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 10 10" fill="currentColor" className="arrow-right-double-icon">
    <path d="M9.97541 5.00057L5.66492 0.690063L4.68284 1.67215L8.01124 5.00057L4.68284 8.32897L5.66492 9.31106L9.97541 5.00057ZM6.05187 5.00057L1.74137 0.690063L0.759277 1.67215L4.0877 5.00057L0.759277 8.32897L1.74137 9.31106L6.05187 5.00057Z" fill="currentColor" />
  </Svg>
);
export const InstaSnappy = () => (
  <Svg xmlns="http://www.w3.org/2000/svg" width="500" zoomAndPan="magnify" viewBox="0 0 375 299.999988" height="400" version="1.0">
    <defs>
      <clipPath id="1aa36d4f09">
        <path d="M 244 12 L 346 12 L 346 114 L 244 114 Z M 244 12 " clipRule="nonzero" />
      </clipPath>
      <clipPath id="5d8858f4aa">
        <path d="M 294.976562 12.074219 C 294.976562 40.039062 272.300781 62.714844 244.335938 62.714844 C 272.300781 62.714844 294.976562 85.390625 294.976562 113.355469 C 294.976562 85.390625 317.652344 62.714844 345.617188 62.714844 C 317.652344 62.714844 294.976562 40.039062 294.976562 12.074219 " clipRule="nonzero" />
      </clipPath>
      <clipPath id="e8e7de3d40">
        <path d="M 333 17.324219 L 359 17.324219 L 359 42.734375 L 333 42.734375 Z M 333 17.324219 " clipRule="nonzero" />
      </clipPath>
      <clipPath id="8746111c62">
        <path d="M 345.714844 17.460938 C 345.714844 24.4375 340.0625 30.089844 333.085938 30.089844 C 340.0625 30.089844 345.714844 35.742188 345.714844 42.71875 C 345.714844 35.742188 351.367188 30.089844 358.34375 30.089844 C 351.367188 30.089844 345.714844 24.4375 345.714844 17.460938 " clipRule="nonzero" />
      </clipPath>
      <clipPath id="e8af883428">
        <path d="M 335 78 L 358 78 L 358 101 L 335 101 Z M 335 78 " clipRule="nonzero" />
      </clipPath>
      <clipPath id="d0fea958c5">
        <path d="M 346.320312 78.53125 C 346.320312 84.664062 341.34375 89.664062 335.1875 89.664062 C 341.320312 89.664062 346.320312 94.640625 346.320312 100.796875 C 346.320312 94.664062 351.292969 89.664062 357.449219 89.664062 C 351.316406 89.664062 346.320312 84.691406 346.320312 78.53125 " clipRule="nonzero" />
      </clipPath>
      <clipPath id="5319d8656f">
        <path d="M 121.269531 90 L 258.707031 90 L 258.707031 226.371094 L 121.269531 226.371094 Z M 121.269531 90 " clipRule="nonzero" />
      </clipPath>
      <clipPath id="688ce01d79">
        <path
          d="M 189.980469 200.097656 C 166.867188 200.097656 148.082031 181.308594 148.082031 158.199219 C 148.082031 135.089844 166.867188 116.277344 189.980469 116.277344 C 213.089844 116.277344 231.878906 135.0625 231.878906 158.175781 C 231.878906 181.285156 213.089844 200.097656 189.980469 200.097656 M 189.980469 90.097656 C 152.355469 90.097656 121.878906 120.597656 121.878906 158.199219 C 121.878906 195.796875 152.378906 226.273438 189.980469 226.273438 C 227.578125 226.273438 258.078125 195.773438 258.078125 158.175781 C 258.078125 120.574219 227.578125 90.074219 189.980469 90.074219 "
          clipRule="nonzero"
        />
      </clipPath>
      <clipPath id="bf1876fe89">
        <path d="M 12 20.789062 L 363 20.789062 L 363 284 L 12 284 Z M 12 20.789062 " clipRule="nonzero" />
      </clipPath>
      <clipPath id="fcb1945a3d">
        <path
          d="M 331.972656 143.421875 L 331.972656 233.640625 C 331.972656 244.121094 323.472656 252.644531 312.992188 252.644531 L 62.039062 252.644531 C 51.558594 252.644531 43.035156 244.144531 43.035156 233.640625 L 43.035156 118.910156 C 43.035156 108.429688 51.535156 99.902344 62.039062 99.902344 L 108.960938 99.902344 L 135.0625 51.871094 L 220.359375 51.871094 C 228.90625 51.871094 235.835938 44.941406 235.835938 36.390625 C 235.835938 27.84375 228.90625 20.914062 220.359375 20.914062 L 116.664062 20.914062 L 90.558594 68.945312 L 62.039062 68.945312 C 34.4375 68.945312 12.074219 91.308594 12.074219 118.910156 L 12.074219 233.640625 C 12.074219 261.21875 34.4375 283.605469 62.039062 283.605469 L 312.96875 283.605469 C 340.570312 283.605469 362.933594 261.242188 362.933594 233.640625 L 362.933594 143.421875 C 362.933594 134.871094 356.003906 127.941406 347.453125 127.941406 C 338.90625 127.941406 331.972656 134.871094 331.972656 143.421875 "
          clipRule="nonzero"
        />
      </clipPath>
    </defs>
    <g clipPath="url(#1aa36d4f09)">
      <g clipPath="url(#5d8858f4aa)">
        <path fill="currentColor" d="M 0 0 L 375.359375 0 L 375.359375 295.667969 L 0 295.667969 Z M 0 0 " fillOpacity="1" fillRule="nonzero" />
      </g>
    </g>
    <g clipPath="url(#e8e7de3d40)">
      <g clipPath="url(#8746111c62)">
        <path fill="currentColor" d="M 0 0 L 375.359375 0 L 375.359375 295.667969 L 0 295.667969 Z M 0 0 " fillOpacity="1" fillRule="nonzero" />
      </g>
    </g>
    <g clipPath="url(#e8af883428)">
      <g clipPath="url(#d0fea958c5)">
        <path fill="currentColor" d="M 0 0 L 375.359375 0 L 375.359375 295.667969 L 0 295.667969 Z M 0 0 " fillOpacity="1" fillRule="nonzero" />
      </g>
    </g>
    <g clipPath="url(#5319d8656f)">
      <g clipPath="url(#688ce01d79)">
        <path fill="currentColor" d="M 0 0 L 375.359375 0 L 375.359375 295.667969 L 0 295.667969 Z M 0 0 " fillOpacity="1" fillRule="nonzero" />
      </g>
    </g>
    <g clipPath="url(#bf1876fe89)">
      <g clipPath="url(#fcb1945a3d)">
        <path fill="currentColor" d="M 0 0 L 375.359375 0 L 375.359375 295.667969 L 0 295.667969 Z M 0 0 " fillOpacity="1" fillRule="nonzero" />
      </g>
    </g>
  </Svg>
);
export const Progress = ({ iconClass }) => (
  <Svg className={iconClass} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
    <path fill="currentColor" d="M60.9 403.1a48 48 0 1 0 96 0 48 48 0 1 0 -96 0zM208 464a48 48 0 1 0 96 0 48 48 0 1 0 -96 0zM369.1 75A48 48 0 1 0 437 142.9 48 48 0 1 0 369.1 75zm0 294.2A48 48 0 1 0 437 437a48 48 0 1 0 -67.9-67.9zM416 256a48 48 0 1 0 96 0 48 48 0 1 0 -96 0z" opacity=".4" />
    <path fill="currentColor" d="M304 48a48 48 0 1 0 -96 0 48 48 0 1 0 96 0zM96 256A48 48 0 1 0 0 256a48 48 0 1 0 96 0zM75 142.9A48 48 0 1 0 142.9 75 48 48 0 1 0 75 142.9z" />
  </Svg>
);
export const AccordionDown = () => (
  <Svg style={{ fontSize: "6px" }} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 10 6">
    <path fill="currentColor" height={10} d="M5 5.25L0.5 0.75H9.5L5 5.25Z" />
  </Svg>
);
export const BackIcon = () => (
  <Svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 15 16" fill="none">
    <path fill="currentColor" d="M3.371 5.24998L5.273 7.15198L4.2125 8.21247L0.5 4.49998L4.2125 0.787476L5.273 1.84798L3.371 3.74998H8.75C10.3413 3.74998 11.8674 4.38212 12.9926 5.50733C14.1179 6.63255 14.75 8.15868 14.75 9.74998C14.75 11.3413 14.1179 12.8674 12.9926 13.9926C11.8674 15.1178 10.3413 15.75 8.75 15.75H2V14.25H8.75C9.94347 14.25 11.0881 13.7759 11.932 12.932C12.7759 12.088 13.25 10.9434 13.25 9.74998C13.25 8.5565 12.7759 7.41191 11.932 6.56799C11.0881 5.72408 9.94347 5.24998 8.75 5.24998H3.371Z" />
  </Svg>
);
export const SortIcon = () => (
  <Svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 10 14" fill="currentColor">
    <path d="M9.16537 4.91667L4.9987 0.75L0.832031 4.91667H9.16537ZM9.16537 9.08333L4.9987 13.25L0.832031 9.08333H9.16537Z" fill="currentColor" />
  </Svg>
);
export const PenIcon = () => (
  <Svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 8 8" fill="none">
    <path fill="currentColor" d="M2.77717 7.10093H7.98516V7.98686H0.0117188V6.10735L4.39711 1.72196L6.27618 3.60192L2.77673 7.10093H2.77717ZM5.02302 1.09604L5.963 0.156062C6.04607 0.0730187 6.15872 0.0263672 6.27618 0.0263672C6.39364 0.0263672 6.50629 0.0730187 6.58936 0.156062L7.84252 1.40922C7.92556 1.49229 7.97222 1.60494 7.97222 1.7224C7.97222 1.83986 7.92556 1.95251 7.84252 2.03558L6.90254 2.97512L5.02347 1.09604H5.02302Z" />
  </Svg>
);
export const PosterBuilder = () => (
  <Svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512">
    <path fill="currentColor" d="M48 64c-8.8 0-16 7.2-16 16l0 288c0 8.8 7.2 16 16 16l480 0c8.8 0 16-7.2 16-16l0-288c0-8.8-7.2-16-16-16l-16 0-32 0L96 64 64 64 48 64zM512 32l16 0c26.5 0 48 21.5 48 48l0 288c0 26.5-21.5 48-48 48l-16 0 0 80c0 8.8-7.2 16-16 16s-16-7.2-16-16l0-80L96 416l0 80c0 8.8-7.2 16-16 16s-16-7.2-16-16l0-80-16 0c-26.5 0-48-21.5-48-48L0 80C0 53.5 21.5 32 48 32l16 0 0-16C64 7.2 71.2 0 80 0s16 7.2 16 16l0 16 384 0 0-16c0-8.8 7.2-16 16-16s16 7.2 16 16l0 16z" />
  </Svg>
);
export const Layers = () => (
  <Svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512">
    <path
      fill="currentColor"
      d="M266.2 4.7C273 1.6 280.5 0 288 0s15 1.6 21.8 4.7l217.4 97.5c10.2 4.6 16.8 14.7 16.8 25.9s-6.6 21.3-16.8 25.9L309.8 251.3c-6.9 3.1-14.3 4.7-21.8 4.7s-15-1.6-21.8-4.7L48.8 153.9C38.6 149.3 32 139.2 32 128s6.6-21.3 16.8-25.9L266.2 4.7zM288 32c-3 0-6 .6-8.8 1.9L69.3 128l210 94.1c2.8 1.2 5.7 1.9 8.8 1.9s6-.6 8.8-1.9l210-94.1-210-94.1C294 32.6 291 32 288 32zM48.8 358.1l45.9-20.6 39.1 17.5L69.3 384l210 94.1c2.8 1.2 5.7 1.9 8.8 1.9s6-.6 8.8-1.9l210-94.1-64.5-28.9 39.1-17.5 45.9 20.6c10.2 4.6 16.8 14.7 16.8 25.9s-6.6 21.3-16.8 25.9L309.8 507.3c-6.9 3.1-14.3 4.7-21.8 4.7s-15-1.6-21.8-4.7L48.8 409.9C38.6 405.3 32 395.2 32 384s6.6-21.3 16.8-25.9zM94.7 209.5l39.1 17.5L69.3 256l210 94.1c2.8 1.2 5.7 1.9 8.8 1.9s6-.6 8.8-1.9l210-94.1-64.5-28.9 39.1-17.5 45.9 20.6c10.2 4.6 16.8 14.7 16.8 25.9s-6.6 21.3-16.8 25.9L309.8 379.3c-6.9 3.1-14.3 4.7-21.8 4.7s-15-1.6-21.8-4.7L48.8 281.9C38.6 277.3 32 267.2 32 256s6.6-21.3 16.8-25.9l45.9-20.6z"
    />
  </Svg>
);
export const MinusIcon = () => (
  <Svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
    <path fill="currentColor" d="M432 256c0 13.3-10.7 24-24 24L40 280c-13.3 0-24-10.7-24-24s10.7-24 24-24l368 0c13.3 0 24 10.7 24 24z" />
  </Svg>
);
export const CropIcon = () => (
  <Svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" fillColor="currentColor">
    <path fill="currentColor" d="M416 118.6l91.3-91.3c6.2-6.2 6.2-16.4 0-22.6s-16.4-6.2-22.6 0L393.4 96 160 96l0 32 201.4 0L128 361.4 128 16c0-8.8-7.2-16-16-16s-16 7.2-16 16l0 80L16 96c-8.8 0-16 7.2-16 16s7.2 16 16 16l80 0 0 240c0 26.5 21.5 48 48 48l208 0 0-32-201.4 0L384 150.6 384 496c0 8.8 7.2 16 16 16s16-7.2 16-16l0-80 80 0c8.8 0 16-7.2 16-16s-7.2-16-16-16l-80 0 0-265.4z" />
  </Svg>
);
export const AccrodianDownIcon = () => (
  <Svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 10 6">
    <path fillRule="evenodd" clipRule="evenodd" d="M5 5.25L0.5 0.75H9.5L5 5.25Z" fill="currentColor" />
  </Svg>
);

export const AccrodianUpIcon = () => (
  <Svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 10 6">
    <path fillRule="evenodd" clipRule="evenodd" d="M5 0.75L9.5 5.25H0.5L5 0.75Z" fill="currentColor" />
  </Svg>
);

// form builder
const TextIcon = () => (
  <Svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M4 7V4H20V7" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M9 20H15" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M12 4V20" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
  </Svg>
);

const PasswordIcon = () => (
  <Svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="3" y="11" width="18" height="11" rx="2" stroke="currentColor" strokeWidth="2" />
    <path d="M7 11V7C7 4.23858 9.23858 2 12 2C14.7614 2 17 4.23858 17 7V11" stroke="currentColor" strokeWidth="2" />
  </Svg>
);

const NumberIcon = () => (
  <Svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M4 9H20" stroke="currentColor" strokeWidth="2" strokeLinecap="round" />
    <path d="M4 15H20" stroke="currentColor" strokeWidth="2" strokeLinecap="round" />
    <path d="M10 3L8 21" stroke="currentColor" strokeWidth="2" strokeLinecap="round" />
    <path d="M16 3L14 21" stroke="currentColor" strokeWidth="2" strokeLinecap="round" />
  </Svg>
);

const MobileNumberIcon = () => (
  <Svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="5" y="2" width="14" height="20" rx="2" stroke="currentColor" strokeWidth="2" />
    <line x1="9" y1="18" x2="15" y2="18" stroke="currentColor" strokeWidth="2" strokeLinecap="round" />
  </Svg>
);

// const TimeIcon = () => (
//   <Svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
//     <circle cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="2" />
//     <path d="M12 6V12L16 14" stroke="currentColor" strokeWidth="2" strokeLinecap="round" />
//   </Svg>
// );

const DateTimeIcon = () => (
  <Svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="3" y="4" width="18" height="18" rx="2" stroke="currentColor" strokeWidth="2" />
    <path d="M3 10H21" stroke="currentColor" strokeWidth="2" />
    <path d="M16 2V6" stroke="currentColor" strokeWidth="2" strokeLinecap="round" />
    <path d="M8 2V6" stroke="currentColor" strokeWidth="2" strokeLinecap="round" />
    <path d="M12 14H16V18" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
  </Svg>
);

const ImageIcon = () => (
  <Svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="3" y="3" width="18" height="18" rx="2" stroke="currentColor" strokeWidth="2" />
    <circle cx="8.5" cy="8.5" r="1.5" fill="currentColor" />
    <path d="M21 15L16 10L5 21" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
  </Svg>
);

const TextAreaIcon = () => (
  <Svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="3" y="3" width="18" height="18" rx="2" stroke="currentColor" strokeWidth="2" />
    <path d="M7 7H17" stroke="currentColor" strokeWidth="2" strokeLinecap="round" />
    <path d="M7 11H17" stroke="currentColor" strokeWidth="2" strokeLinecap="round" />
    <path d="M7 15H13" stroke="currentColor" strokeWidth="2" strokeLinecap="round" />
  </Svg>
);

const ParagraphIcon = () => (
  <Svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M12 5H20" stroke="currentColor" strokeWidth="2" strokeLinecap="round" />
    <path d="M12 9H20" stroke="currentColor" strokeWidth="2" strokeLinecap="round" />
    <path d="M4 13H20" stroke="currentColor" strokeWidth="2" strokeLinecap="round" />
    <path d="M4 17H20" stroke="currentColor" strokeWidth="2" strokeLinecap="round" />
  </Svg>
);

const CheckBoxIcon = () => (
  <Svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="3" y="3" width="18" height="18" rx="2" stroke="currentColor" strokeWidth="2" />
    <path d="M9 12L11 14L15 10" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
  </Svg>
);

const DropDownIcon = () => (
  <Svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M6 9L12 15L18 9" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
  </Svg>
);

const MultipleChoiceIcon = () => (
  <Svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="3" y="3" width="18" height="18" rx="2" stroke="currentColor" strokeWidth="2" />
    <path d="M9 12L11 14L15 10" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M3 19L7 15" stroke="currentColor" strokeWidth="2" strokeLinecap="round" />
    <path d="M21 19L17 15" stroke="currentColor" strokeWidth="2" strokeLinecap="round" />
  </Svg>
);

const HtmlIcon = () => (
  <Svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M4 3L5.77778 20.0899L12 22L18.2222 20.0899L20 3H4Z" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M17 7H7L8 15H16L15 19L12 20L9 19L8.5 16" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
  </Svg>
);

const LineIcon = () => (
  <Svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M3 12H21" stroke="currentColor" strokeWidth="2" strokeLinecap="round" />
  </Svg>
);

const TitleIcon = () => (
  <Svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M4 7V4H20V7" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M12 4V20" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M8 20H16" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
  </Svg>
);
const ParticipantTypeIcon = () => (
  <Svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="12" cy="7" r="4" stroke="currentColor" strokeWidth="2" />
    <path d="M6 21V19C6 16.7909 7.79086 15 10 15H14C16.2091 15 18 16.7909 18 19V21" stroke="currentColor" strokeWidth="2" strokeLinecap="round" />
    <path d="M16 3L20 7M20 3L16 7" stroke="currentColor" strokeWidth="2" strokeLinecap="round" />
  </Svg>
);
const SubmissionsIcon = () => (
  <Svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M3 7V17C3 18.1046 3.89543 19 5 19H19C20.1046 19 21 18.1046 21 17V7C21 5.89543 20.1046 5 19 5H5C3.89543 5 3 5.89543 3 7Z" stroke="currentColor" strokeWidth="2" />
    <path d="M3 7L12 13L21 7" stroke="currentColor" strokeWidth="2" />
    <path d="M12 13V19" stroke="currentColor" strokeWidth="2" />
  </Svg>
);

const ApprovalMessagingIcon = () => (
  <Svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M3 20L5 16H19C20.1046 16 21 15.1046 21 14V6C21 4.89543 20.1046 4 19 4H5C3.89543 4 3 4.89543 3 6V20Z" stroke="currentColor" strokeWidth="2" />
    <path d="M9 10L11 12L15 8" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
  </Svg>
);

const SecurityPrivacyIcon = () => (
  <Svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M12 2L3 7V11C3 16.55 6.84 21.74 12 23C17.16 21.74 21 16.55 21 11V7L12 2Z" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M12 12C13.1046 12 14 11.1046 14 10C14 8.89543 13.1046 8 12 8C10.8954 8 10 8.89543 10 10C10 11.1046 10.8954 12 12 12Z" stroke="currentColor" strokeWidth="2" />
    <path d="M12 12V16" stroke="currentColor" strokeWidth="2" strokeLinecap="round" />
  </Svg>
);
export const PendingIcon = () => (
  <Svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 512 512">
    <path fill="currentColor" d="M256 0C114.6 0 0 114.6 0 256s114.6 256 256 256s256-114.6 256-256S397.4 0 256 0zM256 464c-114.7 0-208-93.31-208-208S141.3 48 256 48s208 93.31 208 208S370.7 464 256 464zM256 232c13.25 0 24-10.75 24-24V72c0-13.25-10.75-24-24-24S232 58.75 232 72v136C232 221.3 242.8 232 256 232z" />
  </Svg>
);

export const AccessedIcon = () => (
  <Svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 512 512">
    <path fill="currentColor" d="M470.6 105.4c12.5 12.5 12.5 32.8 0 45.3l-256 256c-12.5 12.5-32.8 12.5-45.3 0l-128-128c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0L192 338.7 425.4 105.4c12.5-12.5 32.8-12.5 45.3 0z" />
  </Svg>
);

export const AllIcon = () => (
  <Svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 512 512">
    <path
      fill="currentColor"
      d="M40 48C26.7 48 16 58.7 16 72v48c0 13.3 10.7 24 24 24H88c13.3 0 24-10.7 24-24V72c0-13.3-10.7-24-24-24H40zM192 64c-17.7 0-32 14.3-32 32s14.3 32 32 32H480c17.7 0 32-14.3 32-32s-14.3-32-32-32H192zm0 160c-17.7 0-32 14.3-32 32s14.3 32 32 32H480c17.7 0 32-14.3 32-32s-14.3-32-32-32H192zm0 160c-17.7 0-32 14.3-32 32s14.3 32 32 32H480c17.7 0 32-14.3 32-32s-14.3-32-32-32H192zM16 232v48c0 13.3 10.7 24 24 24H88c13.3 0 24-10.7 24-24V232c0-13.3-10.7-24-24-24H40c-13.3 0-24 10.7-24 24zM40 368c-13.3 0-24 10.7-24 24v48c0 13.3 10.7 24 24 24H88c13.3 0 24-10.7 24-24V392c0-13.3-10.7-24-24-24H40z"
    />
  </Svg>
);
const GoogleIcon = () => (
  <Svg className="w-5 h-5" viewBox="0 0 48 48">
    <path fill="#EA4335" d="M24 9.5c3.54 0 6.71 1.22 9.21 3.6l6.85-6.85C35.9 2.38 30.47 0 24 0 14.62 0 6.51 5.38 2.56 13.22l7.98 6.19C12.43 13.72 17.74 9.5 24 9.5z" />
    <path fill="#4285F4" d="M46.98 24.55c0-1.57-.15-3.09-.38-4.55H24v9.02h12.94c-.58 2.96-2.26 5.48-4.78 7.18l7.73 6c4.51-4.18 7.09-10.36 7.09-17.65z" />
    <path fill="#FBBC05" d="M10.53 28.59c-.48-1.45-.76-2.99-.76-4.59s.27-3.14.76-4.59l-7.98-6.19C.92 16.46 0 20.12 0 24c0 3.88.92 7.54 2.56 10.78l7.97-6.19z" />
    <path fill="#34A853" d="M24 48c6.48 0 11.93-2.13 15.89-5.81l-7.73-6c-2.15 1.45-4.92 2.3-8.16 2.3-6.26 0-11.57-4.22-13.47-9.91l-7.98 6.19C6.51 42.62 14.62 48 24 48z" />
  </Svg>
);
export const ToggleOnIcon = () => (
  <Svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512">
    <path fill="currentColor" d="M384 128c70.7 0 128 57.3 128 128s-57.3 128-128 128H192c-70.7 0-128-57.3-128-128s57.3-128 128-128h192zM384 448c106 0 192-86 192-192S490 64 384 64H192C86 64 0 150 0 256s86 192 192 192h192zm0-256c-35.3 0-64 28.7-64 64s28.7 64 64 64c35.3 0 64-28.7 64-64s-28.7-64-64-64zm0 96c-17.7 0-32-14.3-32-32s14.3-32 32-32s32 14.3 32 32s-14.3 32-32 32z" />
  </Svg>
);

export const ToggleOffIcon = () => (
  <Svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512">
    <path fill="currentColor" d="M192 128c70.7 0 128 57.3 128 128s-57.3 128-128 128H192c-70.7 0-128-57.3-128-128s57.3-128 128-128zm0 320c106 0 192-86 192-192S298 64 192 64H192C86 64 0 150 0 256s86 192 192 192zm0-256c-35.3 0-64 28.7-64 64s28.7 64 64 64c35.3 0 64-28.7 64-64s-28.7-64-64-64zm0 96c-17.7 0-32-14.3-32-32s14.3-32 32-32s32 14.3 32 32s-14.3 32-32 32z" />
  </Svg>
);
export const GetIcon = ({ icon, rotate = false }) => {
  const trimmedIcon = icon && typeof icon === "string" ? icon.trim() : "";
  const iconClass = rotate ? "rotate-icon" : "";
  switch (trimmedIcon) {
    case "pending":
      return <PendingIcon />;
    case "on":
      return <ToggleOnIcon />;
    case "off":
      return <ToggleOffIcon />;
    case "google":
      return <GoogleIcon />;
    case "accessed":
      return <AccessedIcon />;
    case "all":
      return <AllIcon />;
    case "submissions":
      return <SubmissionsIcon />;
    case "approvalmessaging":
      return <ApprovalMessagingIcon />;
    case "securityprivacy":
      return <SecurityPrivacyIcon />;
    //form builder
    case "participantType":
    case "participant-type":
      return <ParticipantTypeIcon />;
    case "text":
      return <TextIcon />;
    case "password":
      return <PasswordIcon />;
    case "number":
      return <NumberIcon />;
    case "mobilenumber":
      return <MobileNumberIcon />;
    case "date":
      return <DateIcon />;
    case "datetime":
      return <DateTimeIcon />;
    case "image":
      return <ImageIcon />;
    case "file":
      return <FileIcon />;
    case "textarea":
      return <TextAreaIcon />;
    case "paragraph":
      return <ParagraphIcon />;
    case "checkbox":
      return <CheckBoxIcon />;
    case "dropdown":
      return <DropDownIcon />;
    case "multiplechoice":
      return <MultipleChoiceIcon />;
    case "info":
      return <InfoIcon />;
    case "html":
      return <HtmlIcon />;
    case "line":
      return <LineIcon />;
    case "title":
      return <TitleIcon />;
    //form builder end
    case "up":
      return <AccrodianUpIcon />;
    case "down":
      return <AccrodianDownIcon />;
    case "crop":
      return <CropIcon />;
    case "minus":
      return <MinusIcon />;
    case "layers":
      return <Layers />;
    case "sort":
      return <SortIcon />;
    case "PosterBuilder":
      return <PosterBuilder />;
    case "pen":
      return <PenIcon />;
    case "back":
      return <BackIcon />;
    case "down-small":
      return <AccordionDown />;
    case "down-up":
      return <AccordionDown />;
    case "progress":
      return <Progress iconClass={iconClass} />;
    case "upload":
      return <UploadIcon />;
    case "insta-snap":
      return <InstaSnappy />;
    case "tick":
      return <PlainTickIcon />;
    case "ArrowRightDoubleIcon":
      return <ArrowRightDoubleIcon />;
    case "arrowRight":
      return <ArrowRightIcon />;
    case "ArrowLeft":
      return <ArrowLeft />;
    case "ArrowDoubleLeft":
      return <ArrowDoubleLeft />;
    case "delete":
      return <DeleteIcon />;
    case "deleted":
      return <DeletedIcon />;
    case "success":
      return <SuccessIcon />;
    case "swap":
      return <SwapIcon />;
    case "clone":
      return <CloneIcon />;
    case "play":
      return <PlayIcon />;
    case "pause":
      return <PauseIcon />;
    case "add":
      return <AddIcon />;
    case "edit":
      return <EditIcon />;
    case "home":
      return <HomeIcon />;
    case "filter":
      return <FilterIcon />;
    case "time":
      return <DurationIcon />;
    case "manager":
      return <UserIcon />;
    case "timings":
      return <DurationIcon />;
    case "call":
      return <CallIcon />;
    case "note":
      return <NoteIcon />;
    case "search":
      return <SearchIcon />;
    case "down1":
      return <DownIcon />;
    case "up1":
      return <UpIcon />;
    case "menu":
      return <MenuIcon />;
    case "settings":
      return <SettingsIcon />;
    case "userRole":
    case "user":
      return <UserRoleIcon />;
    case "Close":
    case "close":
      return <CloseIcon />;
    case "userList":
      return <UserListIcon />;
    case "franchise":
      return <FranchiseIcon />;
    case "dateIcon":
      return <DateIcon />;
    case "franchiseList":
      return <FranchiseListIcon />;
    case "dots":
      return <Dots />;
    case "excel":
      return <ExcelIcon />;
    case "reload":
      return <ReloadIcon />;
    case "checked":
      return <PlainTickIcon />;
    case "open":
      return <OpneIcon />;
    case "notification":
      return <NotificationIcon />;
    case "enlarge":
      return <EnlargeIcon />;
    case "dashboard":
      return <DashboardIcon />;
    case "print":
      return <PrintIcon />;
    case "replace":
      return <ReplaceIcon />;
    case "redo":
      return <RedoIcon />;
    case "restoreIcon":
    case "restore":
      return <RestoreIcon />;
    case "errorlog":
      return <ErrorIcon />;
    case "email":
      return <EmailIcon />;
    case "error":
      return <ErrorMarkIcon />;
    case "announcement":
      return <AnnouncementIcon />;
    case "previous":
      return <PreviousIcon />;
    case "next":
      return <NextIcon />;
    case "open-book":
      return <OpenBook />;
    case "faq":
      return <FaqIcon />;
    case "page-section":
      return <PageSectionIcon />;
    case "download":
    case "save":
      return <Download />;
    case "contact":
      return <ContactIcon />;
    default:
      return <GetCustomIcon icon={icon} />;
  }
};

export const SwitchOnIcon = () => (
  <Svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 44 24">
    <rect width="44" height="24" rx="12" fill="currentColor" fillOpacity="0.1" />
    <circle cx="32" cy="12" r="8" fill="currentColor" />
  </Svg>
);

export const SwitchOffIcon = () => (
  <Svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 44 24">
    <rect width="44" height="24" rx="12" fill="currentColor" fillOpacity="0.1" />
    <circle cx="12" cy="12" r="8" fill="currentColor" />
  </Svg>
);
