import "./App.css";
import PageRouter from "./components/router";
import { I18nextProvider } from "react-i18next";
import i18n from "i18next";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { useState } from "react";
import Toast, { ToastProvider } from './components/core/toast';

function App() {
  const [queryClient] = useState(() => new QueryClient());
  return (
    <ToastProvider>
      <I18nextProvider i18n={i18n}>
        <QueryClientProvider client={queryClient}>
          <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}>
            <PageRouter />
          </GoogleOAuthProvider>
        </QueryClientProvider>
        <Toast />
      </I18nextProvider>
    </ToastProvider>
  );
}
export default App;
